import { CategoryDocument, categorySchema } from '@models/category';
import { db } from '@util/firebase';
import { logError } from '@util/logError';
import { slugify } from '@util/urlHelpers';
import {
  collection,
  collectionGroup,
  CollectionReference,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getDocsFromCache,
  limit,
  namedQuery,
  orderBy,
  Query,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getStorage, ref, uploadString } from 'firebase/storage';
import { RideType, rideTypes } from 'models/shared';
const storage = getStorage();

const colRef = collection(
  db,
  'categories'
) as CollectionReference<CategoryDocument>;

const newColRef = collection(
  db,
  'categories_new'
) as CollectionReference<CategoryDocument>;

export const getAllCatsFromJsonBundle = async () => {
  try {
    const url = 'https://bundle.mxlocker.com/category-bundle.json';
    const res = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const json = await res.json();
    return json as {
      'categories-query': CategoryDocument[];
      'category1-query': CategoryDocument[];
      'category2-query': CategoryDocument[];
      'category3-query': CategoryDocument[];
    };
  } catch (e) {
    logError(e, 'getAllCatsFromJsonBundle');
    return {
      'categories-query': [],
      'category1-query': [],
      'category2-query': [],
      'category3-query': [],
    };
  }
};

const getSubcatRef = (lvl: number, id?: string) => {
  if (!lvl) return newColRef;
  const path = `${id ? `${id}/` : ''}category${lvl}_new`;
  return collection(db, path) as Query<CategoryDocument>;
};
export async function getSubcats(level: 0 | 1 | 2 | 3 | 4, id?: string) {
  const { docs } = await getDocs(getSubcatRef(level, id));
  return docs.map((doc) => doc.data());
}

export async function getCategoryByID(id: string) {
  const docRef = doc(db, id);
  const docSnap = await getDoc(docRef);
  return docSnap.data() as CategoryDocument;
}

export async function getMainCats() {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'categories-query');
    if (nQuery) {
      const { docs } = await getDocsFromCache(
        nQuery as Query<CategoryDocument>
      );
      return docs.map((doc) => doc.data());
    }
  }
  const q = query(colRef, orderBy('index', 'asc'));
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data());
}

export async function getCategoryByName(name: string) {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'categories-query');
    if (nQuery) {
      const q = query(
        nQuery as Query<CategoryDocument>,
        where('name', '==', name),
        limit(1)
      );
      const { docs } = await getDocsFromCache(q as Query<CategoryDocument>);
      return (docs[0]?.data() as CategoryDocument) ?? null;
    }
  }
  const q = query(colRef, where('name', '==', name), limit(1));
  const { docs } = await getDocs(q);
  return (docs[0]?.data() as CategoryDocument) ?? null;
}

export async function getCat1ByName(name: string, mainCategory?: string) {
  const cat1Ref = collectionGroup(db, 'category1') as Query<CategoryDocument>;
  const q = query(cat1Ref, where('name', '==', name), limit(1));
  const { docs } = await getDocs(q);
  return (docs[0]?.data() as CategoryDocument) ?? null;
}
export async function getCat1BySlug(slug: string, mainCategory?: string) {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'category1-query');
    if (nQuery) {
      const q = query(
        nQuery as Query<CategoryDocument>,
        where('slug', '==', slug),
        limit(1)
      );
      const { docs } = await getDocsFromCache(q as Query<CategoryDocument>);
      if (mainCategory) {
        // filter out the ones that don't have the main category in the id
        const newDocs = docs.filter((doc) => doc.id.includes(mainCategory));
        return (newDocs[0]?.data() as CategoryDocument) ?? null;
      }
      return (docs[0]?.data() as CategoryDocument) ?? null;
    }
  }
  const cat1Ref = collectionGroup(db, 'category1') as Query<CategoryDocument>;
  const q = query(cat1Ref, where('slug', '==', slug), limit(1));
  const { docs } = await getDocs(q);
  return (docs[0]?.data() as CategoryDocument) ?? null;
}

export async function getCat2ByName(name: string) {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'category2-query');
    if (nQuery) {
      const q = query(
        nQuery as Query<CategoryDocument>,
        where('name', '==', name),
        limit(1)
      );
      const { docs } = await getDocsFromCache(q as Query<CategoryDocument>);
      return (docs[0]?.data() as CategoryDocument) ?? null;
    }
  }
  const cat2Ref = collectionGroup(db, 'category2') as Query<CategoryDocument>;
  const q = query(cat2Ref, where('name', '==', name), limit(1));
  const { docs } = await getDocs(q);
  return (docs[0]?.data() as CategoryDocument) ?? null;
}

export async function getCat3ByName(name: string) {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'category3-query');
    if (nQuery && process.env.NODE_ENV === 'production') {
      const q = query(
        nQuery as Query<CategoryDocument>,
        where('name', '==', name),
        limit(1)
      );
      const { docs } = await getDocsFromCache(q as Query<CategoryDocument>);
      return (docs[0]?.data() as CategoryDocument) ?? null;
    }
  }
  const cat3Ref = collectionGroup(db, 'category3') as Query<CategoryDocument>;
  const q = query(cat3Ref, where('name', '==', name), limit(1));
  const { docs } = await getDocs(q);
  return (docs[0]?.data() as CategoryDocument) ?? null;
}

// export async function getCategory1s(catId: string) {
//   try {
//     if (process.env.NODE_ENV === 'production') {
//       const nQuery = await namedQuery(db, 'category1-query');
//       if (nQuery) {
//         // add catId to query
//         const q = query(
//           nQuery as Query<CategoryDocument>,
//           where('parent_slug', '==', catId)
//         );
//         const { docs } = await getDocsFromCache(q as Query<CategoryDocument>);
//         return docs.map((doc) => doc.data());
//       }
//     }
//     const ref = collection(
//       colRef,
//       catId,
//       'category1'
//     ) as CollectionReference<CategoryDocument>;
//     const { docs } = await getDocs(ref);
//     return docs.map((doc) => doc.data());
//   } catch (e) {
//     return [];
//   }
// }

// cat1Id: categories_new/adventure/category1_new/riding-gear
export async function getCategory2s(cat1Id: string) {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'category2-query');
    if (nQuery) {
      const q = query(nQuery as Query<CategoryDocument>);
      const { docs } = await getDocsFromCache(q as Query<CategoryDocument>);
      return docs
        .map((doc) => doc.data())
        .filter((doc) => doc.id.includes(cat1Id));
    }
  }
  const subCat = collection(
    colRef,
    cat1Id,
    'category2_new'
  ) as CollectionReference<CategoryDocument>;
  const { docs } = await getDocs(subCat);
  return docs.map((doc) => doc.data());
}

export async function getProductCategories(
  catName?: string | null,
  cat1Name?: string | null,
  cat2Name?: string | null,
  cat3Name?: string | null
) {
  if (!catName) return [];
  const promises = [getCategoryByName(catName)];
  if (cat1Name) promises.push(getCat1ByName(cat1Name));
  if (cat2Name) promises.push(getCat2ByName(cat2Name));
  if (cat3Name) promises.push(getCat3ByName(cat3Name));
  return Promise.all(promises);
}

export async function getAllMainCats() {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'categories_new-query');
    if (nQuery) {
      const q = query(
        nQuery as Query<CategoryDocument>,
        orderBy('index', 'asc')
      );
      const { docs } = await getDocsFromCache(q as Query<CategoryDocument>);
      return docs.map((d) => d.data());
    }
  }
  const q = query(colRef, orderBy('index', 'asc'));
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data());
}

export async function getAllCat1s() {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'category1_new-query');
    if (nQuery) {
      const q = query(
        nQuery as Query<CategoryDocument>,
        orderBy('name', 'asc')
      );
      const { docs } = await getDocsFromCache(q as Query<CategoryDocument>);
      return docs.map((d) => d.data());
    }
  }
  const q = query(collectionGroup(db, 'category1_new'), orderBy('name', 'asc'));
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data() as CategoryDocument);
}

export async function getAllCat2s() {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'category2_new-query');
    if (nQuery) {
      const q = query(
        nQuery as Query<CategoryDocument>,
        orderBy('name', 'asc')
      );
      const { docs } = await getDocsFromCache(q as Query<CategoryDocument>);
      return docs.map((d) => d.data());
    }
  }
  const q = query(collectionGroup(db, 'category2_new'), orderBy('name', 'asc'));
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data() as CategoryDocument);
}

export async function getAllCat3s() {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'category3_new-query');
    if (nQuery) {
      const q = query(
        nQuery as Query<CategoryDocument>,
        orderBy('name', 'asc')
      );
      const { docs } = await getDocsFromCache(q as Query<CategoryDocument>);
      return docs.map((d) => d.data());
    }
  }
  const q = query(collectionGroup(db, 'category3_new'), orderBy('name', 'asc'));
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data() as CategoryDocument);
}

export async function getAllCat4s() {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'category4_new-query');
    if (nQuery) {
      const q = query(
        nQuery as Query<CategoryDocument>,
        orderBy('name', 'asc')
      );
      const { docs } = await getDocsFromCache(q as Query<CategoryDocument>);
      return docs.map((d) => d.data());
    }
  }
  const q = query(collectionGroup(db, 'category4_new'), orderBy('name', 'asc'));
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data() as CategoryDocument);
}

export async function getAllCatsNew() {
  try {
    const [cats, cat1s, cat2s, cat3s, cat4s] = await Promise.all([
      getDocs(query(newColRef)),
      getDocs(query(collectionGroup(db, 'category1_new'))),
      getDocs(query(collectionGroup(db, 'category2_new'))),
      getDocs(query(collectionGroup(db, 'category3_new'))),
      getDocs(query(collectionGroup(db, 'category4_new'))),
    ]);

    const categories = cats.docs
      .map((doc) => doc.data())
      .map((cat) => ({
        cat,
        category1s: [] as (CategoryDocument & {
          category2s: (CategoryDocument & {
            category3s?: (CategoryDocument & {
              category4s?: CategoryDocument[];
            })[];
          })[];
        })[],
      }));

    return {
      categories,
      allCatsFlat: [
        ...cats.docs.map((doc) => doc.data() as CategoryDocument),
        ...cat1s.docs.map((doc) => doc.data() as CategoryDocument),
        ...cat2s.docs.map((doc) => doc.data() as CategoryDocument),
        ...cat3s.docs.map((doc) => doc.data() as CategoryDocument),
        ...cat4s.docs.map((doc) => doc.data() as CategoryDocument),
      ],
    };
  } catch (e) {
    logError(e, 'getAllCatsNew');
    return {
      categories: [],
      allCatsFlat: [],
    };
  }
}

export async function getCurrentCatByFullSlug(slug: string) {
  const qCatNew = query(
    collection(db, 'categories_new'),
    where('full_slug', '==', slug),
    limit(1)
  );
  const qCat1New = query(
    collectionGroup(db, 'category1_new'),
    where('full_slug', '==', slug),
    limit(1)
  );
  const qCat2New = query(
    collectionGroup(db, 'category2_new'),
    where('full_slug', '==', slug),
    limit(1)
  );
  const qCat3New = query(
    collectionGroup(db, 'category3_new'),
    where('full_slug', '==', slug),
    limit(1)
  );
  const qCat4New = query(
    collectionGroup(db, 'category4_new'),
    where('full_slug', '==', slug),
    limit(1)
  );
  const res = await Promise.all([
    getDocs(qCatNew),
    getDocs(qCat1New),
    getDocs(qCat2New),
    getDocs(qCat3New),
    getDocs(qCat4New),
  ]);
  // only one doc should be returned
  const returnedDoc = res.flat().find((doc) => !doc.empty);
  if (!returnedDoc) return null;
  return returnedDoc.docs[0].data() as CategoryDocument;
}
export async function getCurrentCatById(id: string) {
  const res = await Promise.all([
    getDoc(doc(db, 'categories_new', id.replace('categories_new/', ''))),
    getDoc(doc(db, 'category1_new', id.replace('categories_new/', ''))),
    getDoc(doc(db, 'category2_new', id.replace('categories_new/', ''))),
    getDoc(doc(db, 'category3_new', id.replace('categories_new/', ''))),
    getDoc(doc(db, 'category4_new', id.replace('categories_new/', ''))),
  ]);
  const returnedDoc = res.flat().find((doc) => doc.exists);
  if (!returnedDoc) return null;
  return returnedDoc.data() as CategoryDocument;
}

export async function uploadCatImage(localUri: string, slug: string) {
  const path = `categories/${slug}-${Date.now()}`;
  const storeImageRef = ref(storage, path);
  const res = await uploadString(storeImageRef, localUri, 'data_url');
  return res.metadata.fullPath;
}
export const getCatID = (
  parent_id: string,
  name: string,
  cat_level: number
) => {
  if (cat_level === 0) return `categories_new/${slugify(name)}`;
  return parent_id + `/category${cat_level}_new/` + slugify(name);
};

export function setCat(
  parent_id: string,
  cat: CategoryDocument,
  isEdit?: boolean
) {
  if (!isEdit) {
    cat.id = getCatID(parent_id, cat.name, cat.cat_level);
  }
  const parsedCat = categorySchema.parse(cat);
  const docRef = doc(db, parsedCat.id);
  return setDoc(docRef, parsedCat);
}

export function deleteCategory(cat: CategoryDocument) {
  const docRef = doc(db, cat.id);
  return deleteDoc(docRef);
}

// NEW CATEGORY QUERIES
export async function getMainCatsNew() {
  const q = query(newColRef);
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data());
}

export async function getMainCatByIdNew(id: string) {
  try {
    const docRef = doc(newColRef, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch (e) {
    logError(e);
    return null;
  }
}

export async function getCat1sNew(parent_id: string) {
  try {
    // get subcollection of parent_id
    const q = query(
      collection(
        newColRef,
        parent_id.replace('categories_new/', ''),
        'category1_new'
      )
    );
    const { docs } = await getDocs(q);
    return docs.map((doc) => doc.data() as CategoryDocument);
  } catch (e) {
    logError(e);
    return [];
  }
}

export async function getCat1ByIdNew(parent_id: string, id: string) {
  try {
    const docId = `${parent_id.replace(
      'categories_new/',
      ''
    )}/category1_new/${id}`;
    const docRef = doc(newColRef, docId);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch (e) {
    logError(e);
    return null;
  }
}

export async function getCat2sNew(parent_id: string) {
  try {
    // get subcollection of parent_id
    const q = query(
      collection(
        newColRef,
        parent_id.replace('categories_new/', ''),
        'category2_new'
      )
    );
    const { docs } = await getDocs(q);
    return docs.map((doc) => doc.data() as CategoryDocument);
  } catch (e) {
    logError(e);
    return [];
  }
}

export async function getCat2ByIdNew(parent_id: string, id: string) {
  try {
    // get subcollection of parent_id
    const docId = `${parent_id.replace(
      'categories_new/',
      ''
    )}/category2_new/${id}`;
    const docRef = doc(newColRef, docId);
    const docSnap = await getDoc(docRef);
    return docSnap.data() as CategoryDocument;
  } catch (e) {
    logError(e);
    return null;
  }
}
export async function getCat3sNew(parent_id: string) {
  try {
    // get subcollection of parent_id
    const q = query(
      collection(
        newColRef,
        parent_id.replace('categories_new/', ''),
        'category3_new'
      )
    );
    const { docs } = await getDocs(q);
    return docs.map((doc) => doc.data() as CategoryDocument);
  } catch (e) {
    logError(e);
    return [];
  }
}

export async function getCat3ByIdNew(parent_id: string, id: string) {
  try {
    // get subcollection of parent_id
    const docId = `${parent_id.replace(
      'categories_new/',
      ''
    )}/category3_new/${id}`;
    const docRef = doc(newColRef, docId);
    const docSnap = await getDoc(docRef);
    return docSnap.data() as CategoryDocument;
  } catch (e) {
    logError(e);
    return null;
  }
}

export async function getCat4sNew(parent_id: string) {
  try {
    // get subcollection of parent_id
    const q = query(
      collection(
        newColRef,
        parent_id.replace('categories_new/', ''),
        'category4_new'
      )
    );
    const { docs } = await getDocs(q);
    return docs.map((doc) => doc.data() as CategoryDocument);
  } catch (e) {
    logError(e);
    return [];
  }
}

export async function getCat4sByIdNewCollectionGroup(id: string) {
  try {
    // get all docs in collection group
    const q = query(collectionGroup(db, 'category4_new'));
    const { docs } = await getDocs(q);
    return docs
      .filter((doc) => id.includes(doc.id))
      .map((doc) => doc.data()) as CategoryDocument[];
  } catch (e) {
    logError(e);
    return null;
  }
}

export async function getCat3sByIdNewCollectionGroup(
  id: string
): Promise<CategoryDocument[] | null> {
  try {
    // get all docs in collection group
    const q = query(collectionGroup(db, 'category3_new'));
    const { docs } = await getDocs(q);
    return docs
      .filter((doc) => doc.id.includes(id))
      .map((doc) => doc.data()) as CategoryDocument[];
  } catch (e) {
    logError(e);
    return null;
  }
}

export const CATEGORIES = [
  'Riding Gear',
  'Bike Parts',
  'Accessories',
  'Bikes',
] as const;

export const SPORTS = [
  'Dirt Bike',
  'ATV',
  'UTV',
  'Street',
  'Cycling',
  'Snowmobile',
  'Casual',
  'Collectibles',
] as const;

export const CYCLING_SPORTS = [
  'Mountain Bikes',
  'Road Bikes',
  'E-Bikes',
] as const;

export const STREET_SPORTS = ['Adventure', 'Cruiser', 'Sport'] as const;

export const NON_SPORT_MAP = {
  '/shop/riding-gear/gear':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/gear',
  '/shop/riding-gear/boots':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/boots',
  '/shop/riding-gear/helmets':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/helmets',
  '/shop/riding-gear/protective':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/protective',
  '/shop/riding-gear/goggles-accessories':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/goggles-accessories',
  '/shop/gear/gloves':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/gear/category3_new/gloves',
  '/shop/gear/gear-combos':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/gear/category3_new/gear-combos',
  '/shop/gear/jerseys':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/gear/category3_new/jerseys',
  '/shop/gear/pants':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/gear/category3_new/pants',
  '/shop/goggles-accessories/goggles':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/goggles-accessories/category3_new/goggles',
  '/shop/goggles-accessories/lenses':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/goggles-accessories/category3_new/lenses',
  '/shop/goggles-accessories/tear-offs':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/goggles-accessories/category3_new/tear-offs',
  '/shop/goggles-accessories/cases':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/goggles-accessories/category3_new/cases',
  '/shop/protective/chest-protectors':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/protective/category3_new/chest-protectors',
  '/shop/protective/knee-braces':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/protective/category3_new/knee-braces',
  '/shop/protective/neck-braces':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/protective/category3_new/neck-braces',
  '/shop/protective/other':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/protective/category3_new/other',
  '/shop/protective/shorts-baselayers':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/protective/category3_new/shorts-baselayers',
  '/shop/protective/wrist-braces':
    'categories_new/dirt-bikes/category1_new/riding-gear/category2_new/protective/category3_new/wrist-braces',
  '/shop/motorcycle-parts':
    'categories_new/street/category1_new/sport/category2_new/bike-parts',
  '/shop/motorcycle-riding-gear':
    'categories_new/street/category1_new/sport/category2_new/riding-gear',
};

export async function exportCategories() {
  const [sports, cat1s, cat2s, cat3s, cat4s] = await Promise.all([
    getDocs(query(newColRef)),
    getDocs(query(collectionGroup(db, 'category1_new'))),
    getDocs(query(collectionGroup(db, 'category2_new'))),
    getDocs(query(collectionGroup(db, 'category3_new'))),
    getDocs(query(collectionGroup(db, 'category4_new'))),
  ]);
  const sportsData = sports.docs.map((doc) => doc.data().id);
  const cat1sData = cat1s.docs.map((doc) => doc.data().id);
  const cat2sData = cat2s.docs.map((doc) => doc.data().id);
  const cat3sData = cat3s.docs.map((doc) => doc.data().id);
  const cat4sData = cat4s.docs.map((doc) => doc.data().id);
  const obj = {
    sports: sportsData,
    cat1s: cat1sData,
    cat2s: cat2sData,
    cat3s: cat3sData,
    cat4s: cat4sData,
  };
  const json = JSON.stringify(obj);
  const blob = new Blob([json], { type: 'application/json' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = 'categories.json';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function getRideTypes(cat: CategoryDocument): RideType[] {
  let ride_types: RideType[] = [];
  const first_id = cat.id.split('/')[1];
  const second_id = cat.id.split('/')[3];
  if (first_id.includes('dirt-bikes')) ride_types.push('Dirt Bikes');
  if (first_id.includes('atv')) ride_types.push('ATV');
  if (first_id.includes('utv')) ride_types.push('UTV');
  if (first_id.includes('snowmobiles')) ride_types.push('Snow');
  if (first_id.includes('street')) ride_types.push('Street');
  if (second_id?.includes('adventure')) ride_types.push('Adventure');
  if (second_id?.includes('sport')) ride_types.push('Sport');
  if (second_id?.includes('cruiser')) ride_types.push('Cruiser');
  if (first_id.includes('cycling')) ride_types.push('Cycling');
  if (second_id?.includes('mountain-bikes')) ride_types.push('Mountain Bikes');
  if (second_id?.includes('e-bikes')) ride_types.push('E-Bikes');
  if (second_id?.includes('road-bikes')) ride_types.push('Road Bikes');
  if (first_id.includes('collectibles')) ride_types.push('Dirt Bikes');
  return ride_types;
}

export async function updateBreadCrumbsAndRideTypes() {
  const mainCats = await getMainCatsNew();
  const promises = mainCats.map(async (cat) => {
    const ride_types = getRideTypes(cat);
    const breadcrumbs = [
      {
        id: cat.id,
        name: cat.name,
        slug: cat.full_slug,
      },
    ];
    await updateDoc(doc(newColRef, cat.id.replace('categories_new/', '')), {
      ride_types,
      breadcrumbs,
    });
    const cat1s = await getCat1sNew(cat.id);
    const promises = cat1s.map(async (cat1) => {
      const ride_types = getRideTypes(cat1);
      const breadcrumbs = [
        {
          id: cat.id,
          name: cat.name,
          slug: cat.full_slug,
        },
        {
          id: cat1.id,
          name: cat1.name,
          slug: cat1.full_slug,
        },
      ];
      await updateDoc(doc(newColRef, cat1.id.replace('categories_new/', '')), {
        ride_types,
        breadcrumbs,
      });
      const cat2s = await getCat2sNew(cat1.id);
      const promises = cat2s.map(async (cat2) => {
        const ride_types = getRideTypes(cat2);
        const breadcrumbs = [
          {
            id: cat.id,
            name: cat.name,
            slug: cat.full_slug,
          },
          {
            id: cat1.id,
            name: cat1.name,
            slug: cat1.full_slug,
          },
          {
            id: cat2.id,
            name: cat2.name,
            slug: cat2.full_slug,
          },
        ];
        await updateDoc(
          doc(newColRef, cat2.id.replace('categories_new/', '')),
          {
            ride_types,
            breadcrumbs,
          }
        );
        const cat3s = await getCat3sNew(cat2.id);
        const promises = cat3s.map(async (cat3) => {
          const ride_types = getRideTypes(cat3);
          const breadcrumbs = [
            {
              id: cat.id,
              name: cat.name,
              slug: cat.full_slug,
            },
            {
              id: cat1.id,
              name: cat1.name,
              slug: cat1.full_slug,
            },
            {
              id: cat2.id,
              name: cat2.name,
              slug: cat2.full_slug,
            },
            {
              id: cat3.id,
              name: cat3.name,
              slug: cat3.full_slug,
            },
          ];
          await updateDoc(
            doc(newColRef, cat3.id.replace('categories_new/', '')),
            {
              ride_types,
              breadcrumbs,
            }
          );
          const cat4s = await getCat4sNew(cat3.id);
          const promises = cat4s.map(async (cat4) => {
            const ride_types = getRideTypes(cat4);
            const breadcrumbs = [
              {
                id: cat.id,
                name: cat.name,
                slug: cat.full_slug,
              },
              {
                id: cat1.id,
                name: cat1.name,
                slug: cat1.full_slug,
              },
              {
                id: cat2.id,
                name: cat2.name,
                slug: cat2.full_slug,
              },
              {
                id: cat3.id,
                name: cat3.name,
                slug: cat3.full_slug,
              },
            ];
            await updateDoc(
              doc(newColRef, cat4.id.replace('categories_new/', '')),
              {
                ride_types,
                breadcrumbs,
              }
            );
          });
          await Promise.all(promises);
        });
        await Promise.all(promises);
      });
      await Promise.all(promises);
    });
    await Promise.all(promises);
  });
  await Promise.all(promises);
}

export function getFullSlugfromCategoryId({
  id,
  isMasterProduct,
}: {
  id: string;
  isMasterProduct?: boolean;
}) {
  const base = '/shop';
  const parts = id.split('/');
  // 'categories_new/atv/category1_new/atv-parts' -> atv-parts
  let sport = parts[1];
  let cat1 = parts[3];
  let cat2 = parts[5];
  let cat3 = parts[7];
  let cat4 = parts[9];
  if (cat4) {
    if (['dirt-bikes', 'atv', 'utv', 'snowmobiles'].includes(sport)) {
      // /shop/cat1/cat4
      return `${base}/${cat1}/${cat4}`;
    } else {
      // /shop/sport/cat1-cat4
      if (cat1.endsWith('s')) {
        return `${base}/${sport}/${cat1.slice(0, -1)}-${cat4}`;
      }
      return `${base}/${sport}/${cat1}-${cat4}`;
    }
  } else if (cat3) {
    if (['dirt-bikes', 'atv', 'utv', 'snowmobiles'].includes(sport)) {
      if (isMasterProduct) {
        // shop/cat1/cat3
        return `${base}/${cat1}/${cat3}`;
      } else {
        // /shop/cat1/cat2/cat3
        if (cat1.includes('parts') || cat1.includes('accessories')) {
          if (sport.endsWith('s')) {
            return `${base}/${sport.slice(0, -1)}-${cat1}/${cat2}/${cat3}`;
          } else {
            return `${base}/${sport}-${cat1}/${cat2}/${cat3}`;
          }
        } else if (cat1.includes('riding-gear')) {
          if (sport === 'dirt-bikes') {
            return `${base}/${cat1}/dirt-bike-and-motocross-${cat3}`;
          }
          if (sport.endsWith('s')) {
            return `${base}/${cat1}/${sport.slice(0, -1)}-${cat3}`;
          }
          return `${base}/${cat1}/${sport}-${cat3}`;
        }
      }
    } else if (cat1.includes('parts') && ['street'].includes('sport')) {
      return `${base}/motorcycle-parts/${cat2}/${cat3}`;
    } else {
      if (cat2 === 'riding-gear') {
        if (
          STREET_SPORTS.map((s) => slugify(s)).includes(
            cat1 as (typeof STREET_SPORTS)[number]
          )
        ) {
          return `${base}/motorcycle/${cat1}-${cat3}`;
        }
        return `${base}/${sport}/${cat1}-${cat3}`;
      } else {
        if (cat2.endsWith('bikes')) {
          return `${base}/${cat2}/${cat3}`;
        }
        // /shop/cat1-cat2/cat3
        if (cat1.endsWith('s')) {
          cat2 = cat2.replace(cat1.slice(0, -1) + '-', '');
          return `${base}/${cat1.slice(0, -1)}-${cat2}/${cat3}`;
        }
        cat2 = cat2.replace(cat1 + '-', '');
        return `${base}/${cat1}-${cat2}/${cat3}`;
      }
    }
  } else if (cat2) {
    if (['dirt-bikes', 'atv', 'utv', 'snowmobiles'].includes(sport)) {
      if (cat1.includes('parts')) {
        return `${base}/${cat1}/${cat2}`;
      } else if (cat1.includes('accessories')) {
        if (sport.endsWith('s')) {
          return `${base}/${sport.slice(0, -1)}-${cat1}/${cat2}`;
        }
        return `${base}/${sport}-${cat1}/${cat2}`;
      } else if (cat1.includes('riding-gear')) {
        if (sport === 'dirt-bikes') {
          return `${base}/${cat1}/dirt-bike-and-motocross-${cat2}`;
        }
        if (sport.endsWith('s')) {
          return `${base}/${cat1}/${sport.slice(0, -1)}-${cat2}`;
        }
        return `${base}/${cat1}/${sport}-${cat2}`;
      }
    } else if (cat1.includes('parts') && ['street'].includes(sport)) {
      return `${base}/motorcycle-parts/${cat2}`;
    } else {
      if (cat2.endsWith('bikes')) {
        return `${base}/${cat2}`;
      }
      if (cat1.endsWith('s')) {
        return `${base}/${cat1.slice(0, -1)}-${cat2.replace(
          cat1.slice(0, -1) + '-',
          ''
        )}`;
      }
      return `${base}/${cat1}-${cat2.replace(cat1 + '-', '')}`;
    }
  } else if (cat1) {
    if (sport === 'casual') {
      return `${base}/${sport}/${cat1}`;
    }
    if (sport === 'dirt-bikes' || sport === 'snowmobiles') {
      if (cat1.includes(sport.slice(0, -1))) {
        return `${base}/${cat1}`;
      }
      return `${base}/${sport.slice(0, -1)}-${cat1}`;
    }
    if (cat1.includes(sport)) {
      return `${base}/${cat1}`;
    }
    if (sport === 'street') {
      return `${base}/motorcycle/${cat1}`;
    }
    return `${base}/${sport}-${cat1}`;
  } else if (sport) {
    return `${base}/${sport}`;
  }
  return `${base}`;
}

export function getProductMap(
  breadcrumbs: CategoryDocument['breadcrumbs'],
  id: string
): CategoryDocument['product_cats'] | null {
  if (!breadcrumbs.length) {
    console.error('Empty breadcrumbs id: ', id);
    return null;
  }
  const name =
    breadcrumbs[0].name === 'Snowmobiles' ? 'Snow' : breadcrumbs[0].name;

  if (name === 'Casual' || name === 'Collectibles') {
    if (name === 'Casual' && breadcrumbs[1])
      return {
        category: name,
        category1: breadcrumbs[1].name,
        ride_type: null,
        category2: null,
        category3: null,
        category4: null,
      };
    return {
      category: name,
      ride_type: null,
      category1: null,
      category2: null,
      category3: null,
      category4: null,
    };
  }
  const parse = rideTypes.safeParse(name);
  if (!parse.success) {
    console.error('Failed to parse main id: ', name, id);
    return null;
  }
  const mainCat = parse.data;
  const obj = breadcrumbs.reduce((acc, cur, i) => {
    if (i === 0) acc.ride_type = mainCat;
    else if (i === 1) {
      if (cur.name.includes('Parts')) acc.category = 'Bike Parts';
      else if (cur.name.includes('Accessories')) acc.category = 'Accessories';
      else if (cur.name.includes('Gear') || cur.name.includes('Apparel'))
        acc.category = 'Riding Gear';
    } else if (i === 2) acc.category1 = cur.name;
    else if (i === 3) acc.category2 = cur.name;
    else if (i === 4) acc.category3 = cur.name;
    return acc;
  }, {} as CategoryDocument['product_cats']);
  return obj;
}

export function getLastProductCat(cat: CategoryDocument) {
  if (cat.product_cats.category4) return cat.product_cats.category4;
  if (cat.product_cats.category3) return cat.product_cats.category3;
  if (cat.product_cats.category2) return cat.product_cats.category2;
  if (cat.product_cats.category1) return cat.product_cats.category1;
  return cat.product_cats.category ?? cat.name;
}
