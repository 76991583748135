import { Availability } from '@c/dashboard/pages/ListingsPage';
import { CategoryDocument } from '@models/category';
import { logEvent } from '@util/analytics';
import { db, FirebaseCallable, functions, httpsCallable } from '@util/firebase';
import { BaseGetProductsArgs } from '@util/getPropsFromContext';
import { client } from '@util/getTypesense';
import { PageSideFilters } from '@util/hooks/useProductsPage';
import { isMobile, nonNullable } from '@util/index';
import { logError } from '@util/logError';
import { ReasonTypes } from '@util/maps/report-reasons';
import { BlogDocument } from '@util/types/firestore/blog';
import {
  arrayRemove,
  arrayUnion,
  collection,
  CollectionReference,
  deleteField,
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  getDocsFromCache,
  increment,
  limit,
  loadBundle,
  namedQuery,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { SearchResponse } from 'instantsearch.js';
import { BannerDocument } from 'models/banner';
import { CuratedListDocument } from 'models/curated_list';
import {
  CauseDocument,
  Condition,
  FacetCounts,
  GetForYouProductsOptions,
  GetProductArgs,
  ProductDocument,
  ProductResultsPaginated,
  ProductResultsPaginatedByRef,
  productSchema,
  ProductSortOption,
  Variation,
} from 'models/product';
import { ApiResponse, Bike } from 'models/shared';
import { PublicUserDocument, UserDocument, UserProfile } from 'models/user';
import { SearchParamsWithPreset } from 'typesense/lib/Typesense/Documents';
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch';
import { getBlogs } from '../blog';
import { getBrandByName } from '../brands/brands.service';
import { getCategoryByID } from '../categories';
import { getActivityVector } from '../users';
export const DEFAULT_LIMIT: number = 12;

export const productsRef = collection(
  db,
  'products'
) as CollectionReference<ProductDocument>;

export const usersRef = collection(
  db,
  'users'
) as CollectionReference<UserDocument>;

type HomePageData = {
  'featured-products-query': ProductDocument[];
  'recent-products-query': ProductDocument[];
  'featured-bikes-query': ProductDocument[];
  'recent-bikes-query': ProductDocument[];
  'recent-mountain-bikes-query': ProductDocument[];
  'sold-products-query': ProductDocument[];
  'onsale-products-query': ProductDocument[];
  'featured-sellers-query': PublicUserDocument[];
  'seller1-products-query': ProductDocument[];
  'seller2-products-query': ProductDocument[];
  'seller3-products-query': ProductDocument[];
  'curated-lists-query': CuratedListDocument[];
  'banners-query': BannerDocument[];
};

const fetchBundle = async () => {
  try {
    const url = process.env.NEXT_PUBLIC_IS_STAGING
      ? 'https://storage.googleapis.com/mx-locker-staging.appspot.com/common-files/firestore-bundle.txt'
      : 'https://bundle.mxlocker.com/firestore-bundle.txt';
    const res = await fetch(url);
    const text = await res.text();
    await loadBundle(db, text);
  } catch (e) {
    logError('homeBundle: ' + (e as Error).message);
  }
};

export const getHomePageData = async () => {
  const url = process.env.NEXT_PUBLIC_IS_STAGING
    ? 'https://storage.googleapis.com/mx-locker-staging.appspot.com/common-files/firestore-bundle.json'
    : 'https://bundle.mxlocker.com/firestore-bundle.json';
  const res = await fetch(url, {
    headers: {
      'content-type': 'application/json',
    },
  });
  const jsonData: HomePageData = await res.json();
  return jsonData;
};

export const getProductsByIdQuery = (ids: string[]) =>
  query(productsRef, where('id', 'in', [...ids]));

export const getAuctionsQuery = () =>
  query(
    productsRef,
    where('is_auction', '==', true),
    where('out_of_stock', '==', false),
    // order by ending soonest
    orderBy('end_time', 'asc'),
    limit(10)
  );

export async function getProductBySlug(
  args: GetProductArgs
): Promise<ApiResponse<ProductDocument | null>> {
  if (args.slug && args.slug.length === 16 && !isNaN(args.slug as any)) {
    const product = await getProductByItemNumber(+args.slug);
    if (product) return { results: product };
  }
  const q = query(productsRef, where('slug', '==', args.slug), limit(1));
  const snapshot = await getDocs(q);
  const data = snapshot.docs?.[0]?.data() ?? null;
  if (!data) return { results: null };
  return { results: cleanLocation(data) };
}

async function getProductByItemNumber(
  num: number
): Promise<ProductDocument | null> {
  const q = query(productsRef, where('item_number', '==', num), limit(1));
  const snapshot = await getDocs(q);
  return snapshot.docs?.[0]?.data() ?? null;
}

export async function getProductByIdNotClean(id: string) {
  const d = doc(productsRef, id);
  const snapshot = await getDoc(d);
  return snapshot.exists() ? snapshot.data() : null;
}

export async function getProductById(id: string) {
  const d = doc(productsRef, id);
  const snapshot = await getDoc(d);
  return snapshot.exists() ? cleanLocation(snapshot.data()) : null;
}

export async function getMostRecentProductSlugs(): Promise<
  ApiResponse<string[]>
> {
  const q = query(productsRef, orderBy('date_added', 'desc'), limit(1000));
  const docsResponse = await getDocs(q);
  const slugs = docsResponse.docs.map((doc) => doc.get('slug')).filter(Boolean);
  return {
    results: slugs,
  };
}

export async function getProductsByIds(
  ids: string[]
): Promise<ProductDocument[]> {
  if (ids.length === 0) return [];
  const q = query(productsRef, where('id', 'in', ids));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((d) => cleanLocation(d.data()));
}

export async function getProductsByIdsFromTypesense(
  ids: string[],
  filters?: string
): Promise<ProductDocument[]> {
  if (ids.length === 0) return [];
  const index = 'products_alias';

  const results = await client
    .collections(index)
    .documents()
    .search({
      q: '*',
      query_by: 'title,sku',
      filter_by: `id:=[${ids.join(',')}]  ${filters ? `&& ${filters}` : ''}`,
    });

  if (results.hits?.length) {
    return results.hits.map((hit) => hit.document as ProductDocument);
  }

  return [];
}

export async function getProductsBySellerId(
  sellerId: string,
  l = DEFAULT_LIMIT
): Promise<ProductDocument[]> {
  const q = query(
    productsRef,
    where('seller_id', '==', sellerId),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => cleanLocation(doc.data())) ?? [];
}
export async function getLiveListingsCountFromTypesense(
  sellerId: string
): Promise<number> {
  const products = await client
    .collections('products_alias')
    .documents()
    .search({
      q: '',
      query_by: 'title',
      filter_by: `seller_id:${sellerId}`,
    });
  return products.found;
}

export async function getDraftProductsBySellerId(seller_id: string) {
  const q = query(
    productsRef,
    where('seller_id', '==', seller_id),
    where('is_draft', '==', true)
  );
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => cleanLocation(doc.data())) ?? [];
}

export async function getProductsByPurchaseEntryID(
  purchaseEntryID: string
): Promise<ProductDocument[]> {
  const q = query(
    productsRef,
    where('purchase_entry_id', '==', purchaseEntryID),
    orderBy('date_added', 'desc')
  );
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => cleanLocation(doc.data())) ?? [];
}

export async function getProducts(
  args: BaseGetProductsArgs
): ProductResultsPaginated {
  return await getProductsFromTypesense(args);
}

export interface GetDirtBikesArgs {
  page: number;
  onSale?: boolean;
  types: string[];
  bikeMakes: string[];
  bikeModels: string[];
  bikeYears: string[];
  engineSizes: string[];
  conditions: Condition[];
  minPrice: number | null;
  maxPrice: number | null;
  sort: ProductSortOption;
  location?: {
    lat: number;
    lng: number;
    address: string;
  } | null;
  radius?: number;
  keyword: string;
  brands: string[];
  is_featured?: boolean;
  letterSizes: string[];
  numberSizes: string[];
}
export async function getDirtBikes(
  args: GetDirtBikesArgs
): ProductResultsPaginated {
  if (args.location || argsRequireTypesense(args)) {
    // get dirt bikes from typesense
    return await getDirtBikesFromTypesense(args);
  }
  const constraints = [
    where('category', '==', 'Dirt Bikes'),
    where('out_of_stock', '==', false),
    args.bikeMakes && args.bikeMakes.some((s) => s !== '')
      ? where('make', 'in', args.bikeMakes)
      : null,
    args.bikeModels && args.bikeModels.some((s) => s !== '')
      ? where('model', 'in', args.bikeModels)
      : null,
    args.bikeYears && args.bikeYears.some((s) => s !== '')
      ? where(
          'year',
          'in',
          args.bikeYears.map((y) => +y)
        )
      : null,
    args.conditions && args.conditions.some((c) => c === 'New' || c === 'Used')
      ? args.conditions.includes('New')
        ? where('condition', '==', 'New')
        : where('condition', 'in', ['Good', 'Like new', 'Used'])
      : null,
    args.engineSizes && args.engineSizes.some((s) => s !== '')
      ? where(
          'engine',
          'in',
          args.engineSizes.map((e) => +e)
        )
      : null,
    args.onSale ? where('on_sale', '==', true) : null,
    args.types && args.types.some((c) => c !== '')
      ? where('category1', 'in', args.types)
      : null,
  ].filter(nonNullable);

  const sortOrder =
    args.sort === 'priceAsc'
      ? orderBy('price', 'asc')
      : args.sort === 'priceDesc'
      ? orderBy('price', 'desc')
      : args.sort === 'mostPopular'
      ? orderBy('favorite_count', 'desc')
      : orderBy('date_added', 'desc');

  const noLimitQ = query(productsRef, ...constraints, sortOrder);
  const count = (await getCountFromServer(noLimitQ)).data().count;
  const q = query(productsRef, ...constraints, sortOrder, limit(24));
  const snapshot = await getDocs(q);
  const bikes = snapshot.docs.map((doc) => cleanLocation(doc.data())) ?? [];
  return {
    results: bikes,
    pageInfo: {
      totalResults: count,
      totalPages: Math.ceil(count / 24),
      page: args.page,
      itemsPerPage: 24,
    },
  };
}

export const getStaticMapUrl = (lat: number, lng: number) => {
  // Don't want to give away exact location :)
  lat = +lat.toFixed(2);
  lng = +lng.toFixed(2);
  const size = isMobile() ? '300x300' : '500x500';
  var uri = 'https://maps.googleapis.com/maps/api/staticmap?';
  var staticMapSrc = 'center=' + lat + ',' + lng;
  staticMapSrc += '&zoom=6';
  staticMapSrc += `&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`;
  staticMapSrc += '&size=' + size;
  return uri + encodeURI(staticMapSrc);
};

export async function getProductsFromTypesense(
  args: BaseGetProductsArgs
): ProductResultsPaginated {
  const page = Math.max(args.page ?? 1, 1);
  const limit = args.limit ?? 12;

  const filters = generateTypesenseFilters(args);
  const sort_by = getTypesenseSortBy(args.sort);

  const index = args.recently_sold ? 'sold_products' : 'products_alias';

  const searchParams: SearchParamsWithPreset = {
    q: args.keyword ?? '',
    preset: 'main_search',
    per_page: limit,
    page: page,
    filter_by: filters,
    facet_by:
      'brand,condition,category,category1,category2,category3,seller_type,gender,size,variations.color',
    max_facet_values: 25,
    sort_by,
  };
  let res;
  try {
    res = await client.collections(index).documents().search(searchParams);
  } catch (e) {
    logError(e);
    res = {
      hits: [],
      found: 0,
      facet_counts: [],
    };
  }

  // Try again with page 1 if no results (there's probably a better way to do this?)
  if (!res.hits?.length && page > 1) {
    res = await client
      .collections(index)
      .documents()
      .search({
        q: args.keyword ?? '',
        preset: 'main_search',
        per_page: limit,
        page: 1,
        filter_by: filters,
        sort_by,
      });
  }
  const brands =
    res.facet_counts
      ?.filter((f) => f.field_name === 'brand')
      .map((f) => f.counts.map((c) => c.value))
      .flat()
      .filter((b) => b !== 'na') ?? [];
  const totalPages = Math.ceil(res.found / limit) || 1;
  return {
    results:
      res.hits?.map((h) => ({
        ...cleanLocation(h.document as ProductDocument),
        out_of_stock: args.recently_sold
          ? true
          : (h.document as ProductDocument).out_of_stock,
      })) ?? [],
    brands,
    ...(res.facet_counts?.length && {
      facet_counts: res.facet_counts as FacetCounts[],
    }),
    pageInfo: {
      page: page > totalPages ? totalPages : page,
      totalPages: totalPages,
      itemsPerPage: limit,
      totalResults: res.found,
    },
  };
}

export async function getPersonalizedResults(args: BaseGetProductsArgs) {
  // content based filtering only for now
  // first get user_profile from typesense
  if (!args.uid) {
    throw new Error('uid is required for personalized results');
  }
  // Use search because retrieve requires admin key
  const user_profile_res = await client
    .collections<UserProfile>('user_profiles_alias')
    .documents()
    .search({
      q: '*',
      filter_by: `uid:${args.uid}`,
      query_by: 'uid',
    });

  const user_profile = user_profile_res.hits?.[0]?.document as any;
  if (!user_profile) {
    throw new Error('user profile not found');
  }

  const activity_profile = user_profile.activity_profile;
  const filters = generateTypesenseFilters(args);
  const regQuery = await client
    .collections('products_alias')
    .documents()
    .search({
      q: args.keyword ?? '*',
      query_by: 'title',
      per_page: args.limit,
      page: args.page,
      filter_by: filters,
    });
  const totalFound = regQuery.found;
  const activity_vector_query = `embedding:([${activity_profile}], k:${totalFound})`;
  //  use typesense vector search to get products similar to profile
  const searches: MultiSearchRequestSchema[] = [
    // Featured + Activity
    {
      collection: 'products_alias',
      q: '*',
      vector_query: activity_vector_query,
      query_by: 'title',
      filter_by: `${filters} && is_featured:true`,
      offset: (args.page - 1) * (args.limit / 4),
      limit: args.limit,
      // per_page: args.limit,
      // page: args.page,
    },
    // Trending + Activity
    {
      collection: 'products_alias',
      q: '*',
      query_by: 'title',
      vector_query: activity_vector_query,
      filter_by: `${filters} && favorite_count:>2`,
      offset: (args.page - 1) * (args.limit / 4),
      limit: args.limit,
    },
    // Activity
    {
      collection: 'products_alias',
      q: '*',
      query_by: 'title',
      vector_query: activity_vector_query,
      filter_by: filters,
      offset: (args.page - 1) * (args.limit / 4),
      limit: args.limit,
    },
    // Recent
    {
      collection: 'products_alias',
      q: '*',
      query_by: 'title',
      filter_by: filters,
      // per_page: args.limit,
      // page: args.page,
      sort_by: 'date_added:desc',
      offset: (args.page - 1) * (args.limit / 4),
      limit: args.limit,
    },
  ];

  const searchRequests = {
    searches: searches as MultiSearchRequestSchema[],
  };

  const results = await client.multiSearch.perform<ProductDocument[]>(
    searchRequests,
    {
      facet_by:
        'brand,condition,category,category1,category2,category3,seller_type,gender,size',
      max_facet_values: 25,
    }
  );

  let docs = results.results
    .map((res) => res?.hits?.map((h) => h.document as ProductDocument) ?? [])
    .flat();

  // filter out duplicates && if user_profiles.interactions.views includes it
  // ( kinda like a baby bloom filter without the complex hash table)
  //https://brilliant.org/wiki/bloom-filter/#:~:text=A%20bloom%20filter%20is%20a,is%20added%20to%20the%20set.
  docs = docs.filter(
    (d, i) =>
      // duplicates
      docs.findIndex((o) => o.id === d.id) === i
    // Not implemented yet, should use session views rather than long term views
    // &&
    // // // not in views
    // (!user_profile.interactions.views.some(
    //   (v: { pid: string }) => v.pid === d.id
    // ) ||
    //   // or is in favorites
    //   user_profile.interactions.favorites.some(
    //     (f: { pid: string }) => f.pid === d.id
    //   ))
  );

  const n = searches.length;
  docs = docs.reduce((acc, val, index) => {
    const position = ((index % n) * docs.length) / n + Math.floor(index / n);
    if (val) {
      acc[position] = val;
    }
    return acc;
  }, [] as ProductDocument[]);

  docs = docs.filter((d) => d);

  if (docs.length > args.limit) {
    docs = docs.slice(0, args.limit);
  }

  if (docs.length < args.limit) {
    // get more results from typesense
    const moreResults = await client.multiSearch.perform<ProductDocument[]>(
      {
        searches: [
          {
            collection: 'products_alias',
            vector_query: activity_vector_query,
            q: args.keyword ?? '*',
            query_by: 'title',
            filter_by: filters,
            per_page: args.limit,
            page: args.page,
          },
        ],
      },
      {
        facet_by:
          'brand,condition,category,category1,category2,category3,seller_type,gender,size',
        max_facet_values: 25,
      }
    );
    docs = [
      ...docs,
      ...(moreResults.results[0].hits?.map(
        (h) => h.document as ProductDocument
      ) ?? []),
    ];
  }

  const totalPages = Math.ceil(totalFound / args.limit) || 1;
  return {
    results: docs,
    brands: getBrandsFromResult(docs),
    facet_counts: getMultiSearchFacetCounts(results.results),
    pageInfo: {
      page: args.page > totalPages ? totalPages : args.page,
      totalPages: totalPages,
      itemsPerPage: args.limit,
      totalResults: totalFound,
    },
  };
}

export function getTypesenseSortBy(sort: ProductSortOption) {
  if (sort === 'priceAsc') return 'price:asc';
  if (sort === 'priceDesc') return 'price:desc';
  if (sort === 'newest') return `date_added:desc`;
  if (sort === 'bestMatch') return '_text_match:desc';
  if (sort === 'soldDate') return 'sold_date:desc';
  return 'date_added:desc';
}

export async function getDirtBikesFromTypesense(args: GetDirtBikesArgs) {
  let filters = 'category:"Dirt Bikes" && ';
  if (args.is_featured) {
    filters += 'is_featured:true && ';
  }
  if (args.minPrice && args.maxPrice)
    filters += `price:[${args.minPrice}..${args.maxPrice}] && `;
  if (args.minPrice) filters += `price:>=${args.minPrice} && `;
  if (args.maxPrice) filters += `price:<=${args.maxPrice} && `;
  if (args.types?.length) {
    filters += `category1:[${args.types
      .map((type) => `\`${type}\``)
      .join(', ')}] && `;
  }
  if (args.bikeMakes?.length) {
    filters += `make:[${args.bikeMakes.map((m) => `\`${m}\``).join(', ')}] && `;
  }
  if (args.bikeModels?.length) {
    filters += `model:[${args.bikeModels
      .map((model) => `\`${model}\``)
      .join(', ')}] && `;
  }
  if (args.bikeYears?.length) {
    filters += `year:=[${args.bikeYears.join(', ')}] && `;
  }
  if (args.engineSizes?.length) {
    filters += `engine:[${args.engineSizes.join(', ')}] && `;
  }
  if (args.brands?.length) {
    filters += `brand:[${args.brands
      .map((brand) => `\`${brand}\``)
      .join(', ')}] && `;
  }
  if (args.conditions?.length) {
    const condition = args.conditions.includes('New') ? 'New' : 'Used';
    // if its new , filter by new, else filter by not new
    if (condition === 'New') {
      filters += `condition:"${condition}" && `;
    } else {
      filters += `condition:!="New" && `;
    }
  }
  if (args.onSale) {
    filters += 'on_sale:true && ';
  }
  if (args.location) {
    filters += `location:(${args.location.lat}, ${args.location.lng}, ${
      args.radius ?? 250
    } mi) && `;
  }
  if (filters.endsWith('&& ')) filters = filters.slice(0, -4);
  const index = 'products_alias';
  const pre_sort = getTypesenseSortBy(args.sort);
  const sort_by =
    args.location && pre_sort === 'date_added:desc'
      ? `location(${args.location.lat}, ${args.location.lng}):asc`
      : pre_sort;

  const res = await client
    .collections<ProductDocument>(index)
    .documents()
    .search({
      q: '*',
      query_by: 'title',
      per_page: 24,
      page: args.page,
      filter_by: filters,
      sort_by,
    });

  return {
    // results: res.hits?.map((h) => ),
    results:
      res.hits?.map((h) => cleanLocation(h.document as ProductDocument)) ?? [],
    brands: getBrandsFromResult(
      res.hits?.map((h) => h.document as ProductDocument) ?? []
    ),
    pageInfo: {
      page: args.page > res.page ? res.page : args.page,
      totalPages: res.hits?.length ? Math.ceil(res.found / res.hits.length) : 0,
      itemsPerPage: res.hits?.length ?? 0,
      totalResults: res.found,
    },
  };
}

// BEGIN: for-you product fetchers

/**
 * Replicates the functionality of getProducts very closely. Duplicated b/c getProducts is
 * used only in the context of getServerSideProps, where its args are provided from the same
 * context every time (server-side). Might be nice to generalize in the future.
 */
export async function getForYouProducts({
  brand,
  category,
  subCategory,
  userBike,
  userSizes,
  searchLimit = DEFAULT_LIMIT,
  sort = 'mostPopular',
  lastDocSnapshot,
}: GetForYouProductsOptions): ProductResultsPaginatedByRef {
  // Set defaults

  const resultLimit = searchLimit ?? DEFAULT_LIMIT;
  const brands = brand ? [brand] : [];

  // Build Firestore query constraints

  let baseConstraints = [
    where('out_of_stock', '==', false),
    getSortConstraint(sort),
    getBrandsConstraint(brands),
  ];

  if (!!category) {
    baseConstraints.push(getCategoryConstraint(category));
  }

  if (!!subCategory) {
    baseConstraints.push(getSubCategoryConstraint(subCategory, null, null));
  }

  if (!!userSizes && !!category && !!subCategory) {
    if (!!userSizes.boots && subCategory === 'Boots') {
      baseConstraints.push(getBootSizesConstraint([`${userSizes?.boots}`]));
    }
    if (!!userSizes.helmet && subCategory === 'Helmets') {
      baseConstraints.push(getHelmetSizesConstraint([userSizes?.helmet]));
    }
    if (!!userSizes.jersey && subCategory === 'Jerseys') {
      baseConstraints.push(getShirtSizesConstraint([userSizes?.jersey]));
    }
    if (!!userSizes.pants && subCategory === 'Pants') {
      baseConstraints.push(getPantSizesConstraint([`${userSizes?.pants}`]));
    }
    if (!!userSizes.gloves && subCategory === 'Gloves') {
      baseConstraints.push(getGloveSizesConstraint([userSizes?.gloves]));
    }
  }

  if (!!userBike) {
    let bikeConstraints = {
      makes: [] as string[],
      models: [] as string[],
      years: [] as string[],
    };
    if (!!userBike.make) {
      bikeConstraints.makes = [userBike.make];
    }
    if (!!userBike.model) {
      bikeConstraints.models = [userBike.model];
    }
    if (!!userBike.year) {
      bikeConstraints.years = [`${userBike.year}`];
    }
    baseConstraints.push(getBikeConstraints(bikeConstraints));
  }

  // Build Firestore query and fetch data

  const nonNullConstraints = baseConstraints.filter(nonNullable);

  const queryBase = query(
    productsRef,
    ...nonNullConstraints,
    limit(resultLimit)
  );

  const qFinal = lastDocSnapshot
    ? query(queryBase, startAfter(lastDocSnapshot))
    : queryBase;

  const docsSnapshot = await getDocs(qFinal);

  const docsResponse = docsSnapshot.docs.map((x) => cleanLocation(x.data()));

  // For pagination
  const lastVisible = docsSnapshot.docs[docsSnapshot.docs.length - 1];

  return {
    results: docsResponse,
    brands: getBrandsFromResult(docsResponse),
    lastDocSnapshot: lastVisible,
  };
}

/**
 * Replicated the functionality of getProductsFromTypesense very closely but excluding filters.
 * Should only be used to fetch personalized+unfiltered results from Typesense.
 * See getForYouProducts for information on why it was decided to replicate this.
 */
export async function getForYouProductsFromTypesense(
  searchLimit: number = DEFAULT_LIMIT,
  page: number = 1,
  category?: string
) {
  const index = 'products_alias';

  let filters = '';
  if (category) {
    filters += `category:"${category}" && `;
  } else {
    filters += `category:!="Dirt Bikes" && `;
  }
  if (filters.endsWith('&& ')) filters = filters.slice(0, -4);

  const res = await client.collections(index).documents().search({
    q: '',
    query_by: 'title',
    per_page: searchLimit,
    page: page,
    filter_by: filters,
  });

  return {
    results: res.hits?.map((h) => cleanLocation(h.document as ProductDocument)),
    brands: getBrandsFromResult(
      res.hits?.map((h) => h.document as ProductDocument) ?? []
    ),
    pageInfo: {
      page: page > res.page ? res.page : page,
      totalPages: res.hits?.length ? Math.ceil(res.found / res.hits.length) : 0,
      itemsPerPage: res.hits?.length ?? 0,
      totalResults: res.found,
    },
  };
}

// END: for-you product fetchers

const mainCats: string[] = ['Riding Gear', 'Bike Parts', 'Collectibles'];

export async function getNewArrivals(
  l = DEFAULT_LIMIT
): Promise<ApiResponse<ProductDocument[]>> {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'recent-products-query');
    if (nQuery) {
      const { docs } = await getDocsFromCache(nQuery);
      const results = docs.map((d) =>
        cleanLocation(d.data() as ProductDocument)
      );
      return { results };
    } else fetchBundle();
  }
  const q = query(
    productsRef,
    where('category', 'in', mainCats),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => cleanLocation(d.data()));
  return { results };
}

export async function getPageNewArrivals(
  l = DEFAULT_LIMIT,
  page: string
): Promise<ApiResponse<ProductDocument[]>> {
  const categoryMap: { [key: string]: string } = {
    '/shop/riding-gear': 'Riding Gear',
    '/shop/parts': 'Bike Parts',
    '/shop/accessories': 'Accessories',
  };

  const category = categoryMap[page];
  const conditions = [
    where('category', 'in', mainCats),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l),
  ];

  if (category) {
    conditions.push(where('category', '==', category));
  }

  const q = query(productsRef, ...conditions);
  const { docs } = await getDocs(q);
  const results = docs.map((d) => cleanLocation(d.data()));
  return { results };
}

export async function getFeaturedProducts(l = DEFAULT_LIMIT) {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'featured-products-query');
    if (nQuery) {
      const { docs } = await getDocsFromCache(nQuery);
      const results = docs.map((d) =>
        cleanLocation(d.data() as ProductDocument)
      );
      return { results };
    } else fetchBundle();
  }
  const q = query(
    productsRef,
    where('is_featured', '==', true),
    where('category', 'in', mainCats),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => d.data());
  return { results };
}

export async function getFeaturedBikes(l = DEFAULT_LIMIT) {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'featured-bikes-query');
    if (nQuery) {
      const { docs } = await getDocsFromCache(nQuery);
      const results = docs.map((d) =>
        cleanLocation(d.data() as ProductDocument)
      );
      return { results };
    } else fetchBundle();
  }
  const q = query(
    productsRef,
    where('is_featured', '==', true),
    where('category', '==', 'Dirt Bikes'),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => cleanLocation(d.data()));
  return { results };
}

export async function getRecentBikes(l = DEFAULT_LIMIT) {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'recent-bikes-query');
    if (nQuery) {
      const { docs } = await getDocsFromCache(nQuery);
      const results = docs.map((d) =>
        cleanLocation(d.data() as ProductDocument)
      );
      return { results };
    } else fetchBundle();
  }
  const q = query(
    productsRef,
    where('category', '==', 'Dirt Bikes'),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => cleanLocation(d.data()));
  return { results };
}

export async function getAllAuctionsBySellerId(seller_id: string) {
  const q = query(
    productsRef,
    where('seller_id', '==', seller_id),
    where('is_auction', '==', true),
    // where('auction_name', '==', 'Brian Barnes Ironman'),
    where('is_draft', '==', false),
    where('end_time', '>=', Date.now())
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => d.data());
  return { results };
}

export async function getOnSale(l = DEFAULT_LIMIT) {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'onsale-products-query');
    if (nQuery) {
      const { docs } = await getDocsFromCache(nQuery);
      const results = docs.map((d) =>
        cleanLocation(d.data() as ProductDocument)
      );
      return { results };
    } else fetchBundle();
  }
  const q = query(
    productsRef,
    where('on_sale', '==', true),
    where('category', 'in', mainCats),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => d.data());
  return { results };
}

export async function getRecentlySold(l = DEFAULT_LIMIT) {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'sold-products-query');
    if (nQuery) {
      const { docs } = await getDocsFromCache(nQuery);
      const results = docs.map((d) =>
        cleanLocation(d.data() as ProductDocument)
      );
      return { results };
    } else fetchBundle();
  }
  const q = query(productsRef, orderBy('sold_date', 'desc'), limit(l));
  const { docs } = await getDocs(q);
  const results = docs.map((d) => cleanLocation(d.data()));
  return { results };
}

export async function getRecentMTB() {
  if (process.env.NODE_ENV === 'production') {
    const nQuery = await namedQuery(db, 'recent-mountain-bikes-query');
    if (nQuery) {
      const { docs } = await getDocsFromCache(nQuery);
      const results = docs.map((d) =>
        cleanLocation(d.data() as ProductDocument)
      );
      return { results };
    }
  }
  const q = query(
    productsRef,
    where('category', '==', 'Mountain Bikes'),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(12)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => cleanLocation(d.data()));
  return { results };
}

export async function getRecentPaddockPosts(): Promise<BlogDocument[]> {
  const { results, pageInfo } = await getBlogs({
    limit: 3,
    pageInfo: {
      cursor: null,
      direction: 'next',
    },
  });

  return results;
}

export async function getRelatedProducts(
  product: ProductDocument,
  l = DEFAULT_LIMIT
) {
  const index = 'products_alias';
  const productInTypesense = await client
    .collections<ProductDocument & { embedding: number[] }>(index)
    .documents()
    .search({
      q: product.title,
      query_by: 'title',
      per_page: 1,
    });
  const productEmbedding = productInTypesense?.hits?.[0].document.embedding;
  const vector_query = `embedding:([${productEmbedding}], k:${l})`;

  let catIndex = '';
  let catValue = product.category;
  if (product.category3) {
    catIndex = '3';
    catValue = product.category3;
  } else if (product.category2) {
    catIndex = '2';
    catValue = product.category2;
  } else if (product.category1) {
    catIndex = '1';
    catValue = product.category1;
  }

  let filter_by = `category${catIndex}:="${catValue}"`;
  if (product.size?.letter)
    filter_by += ` && (size.letter:=${product.size?.letter} || variations.size:=${product.size?.letter})`;
  if (product.size?.number)
    filter_by += ` && (size.number:=${product.size?.number} || variations.size:=${product.size?.number})`;
  if (
    product.category === 'Bike Parts' &&
    product.fitting_bikes?.bikes?.length
  ) {
    const uniqueMakes = [
      ...new Set(product.fitting_bikes.bikes.map((b) => b.make)),
    ];
    const uniqueModels = [
      ...new Set(product.fitting_bikes.bikes.map((b) => b.model)),
    ];
    filter_by += ` && (fitting_bikes.bikes.make:=[${uniqueMakes.toString()}] && fitting_bikes.bikes.model:=[${uniqueModels.toString()}])`;
  }

  const searchRequests = {
    searches: [
      {
        collection: index,
        q: '*',
        filter_by,
        vector_query,
        per_page: l,
      },
      {
        collection: index,
        q: '*',
        filter_by: `category${catIndex}:="${catValue}"`,
        vector_query,
        per_page: l,
      },
    ] as MultiSearchRequestSchema[],
  };
  const { results } = await client.multiSearch.perform<ProductDocument[]>(
    searchRequests
  );
  const products = (
    results[0]?.hits?.map((h) => h.document as ProductDocument) ?? []
  ).filter((p) => p.id !== product.id);
  if (products.length < l) {
    const moreProducts = (
      results[1]?.hits?.map((h) => h.document as ProductDocument) ?? []
    ).filter(
      (p) => p.id !== product.id && !products.some((p2) => p2.id === p.id)
    );
    products.push(...moreProducts.slice(0, l - products.length));
  }
  return {
    results: products,
  };
}

export async function getMoreFromThisSeller(
  product: ProductDocument,
  l = DEFAULT_LIMIT
): Promise<ProductDocument[]> {
  const q = query(
    productsRef,
    where('seller_id', '==', product.seller_id),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const snapshot = await getDocs(q);
  const products = snapshot.docs.map((doc) => doc.data()) ?? [];
  return products.filter((p) => p.id !== product.id) ?? [];
}

export async function getSellerListingsPaginated(
  sellerId: string,
  isAdmin: boolean,
  page: number,
  category: string | null = null,
  category1: string | null = null,
  brand: string | null = null,
  condition: Condition | 'all' = 'all',
  availability: Availability | 'all' = 'all',
  auctionName: string | null = 'all',
  l: number = 12
) {
  if (!sellerId && !isAdmin)
    return Promise.resolve({
      results: [],
      pageInfo: {
        page: 1,
        totalPages: 1,
      },
    });

  const limitValue = availability === 'paymentFailed' ? null : l;

  const filterConstraints = [
    ...(category !== 'all' ? [where('category', '==', category)] : []),
    ...(category1 !== 'all' ? [where('category1', '==', category1)] : []),
    ...(condition !== 'all' ? [where('condition', '==', condition)] : []),
    ...(brand !== 'all' ? [where('brand', '==', brand)] : []),
    ...(availability !== 'all'
      ? [...getAvailabilityConstraint(availability)]
      : []),
    ...(auctionName !== 'all'
      ? [where('auction_name', '==', auctionName)]
      : []),
  ];

  const orderByField =
    availability === 'notSold' || availability === 'paymentFailed'
      ? null
      : 'date_added';

  const noLimitQ = query(
    productsRef,
    where('seller_id', '==', sellerId),
    ...filterConstraints,
    ...(orderByField ? [orderBy(orderByField, 'desc')] : [])
  );
  const noLimitAdminQ = query(
    productsRef,
    ...filterConstraints,
    ...(orderByField ? [orderBy(orderByField, 'desc')] : [])
  );
  const count = (
    await getCountFromServer(isAdmin ? noLimitAdminQ : noLimitQ)
  ).data().count;
  const totalPages = limitValue ? Math.ceil(count / limitValue) : 1;

  // handle pagination
  let lastVisible = null;
  if (page > 1 && page <= totalPages) {
    const q = query(
      productsRef,
      where('seller_id', '==', sellerId),
      ...filterConstraints,
      ...(orderByField ? [orderBy(orderByField, 'desc')] : []),
      ...(limitValue ? [limit((page - 1) * limitValue)] : [])
    );

    const adminQ = query(
      productsRef,
      ...filterConstraints,
      ...(orderByField ? [orderBy(orderByField, 'desc')] : []),
      ...(limitValue ? [limit((page - 1) * limitValue)] : [])
    );

    const snap = await getDocs(isAdmin ? adminQ : q);
    lastVisible = snap.docs[snap.docs.length - 1];
  }

  const q = query(
    productsRef,
    where('seller_id', '==', sellerId),
    ...filterConstraints,
    ...(orderByField ? [orderBy(orderByField, 'desc')] : []),
    ...(lastVisible ? [startAfter(lastVisible)] : []),
    ...(limitValue ? [limit(limitValue)] : [])
  );
  const adminQ = query(
    productsRef,
    ...filterConstraints,
    ...(orderByField ? [orderBy(orderByField, 'desc')] : []),
    ...(lastVisible ? [startAfter(lastVisible)] : []),
    ...(limitValue ? [limit(limitValue)] : [])
  );

  const snap = await getDocs(isAdmin ? adminQ : q);

  let results = snap.docs.map((d) => cleanLocation(d.data()));
  if (availability === 'paymentFailed') {
    results = results.filter((r) => r.og_price !== r.price);
  }
  return {
    results,
    pageInfo: {
      page,
      totalPages,
    },
  };
}

export async function deleteListing(productId: string) {
  const docRef = doc(productsRef, productId);
  await updateDoc(docRef, {
    is_draft: false,
    date_updated: Date.now(),
    date_added: deleteField(),
    out_of_stock: true,
  });
}

export async function markAsSold(product: ProductDocument): Promise<void> {
  const bikeInfo: {
    item_id: string;
    value: number;
    year?: number;
    make?: string;
    model?: string;
  } = { item_id: product.id, value: product.price };
  if (product.year) bikeInfo.year = product.year;
  if (product.make) bikeInfo.make = product.make;
  if (product.model) bikeInfo.model = product.model;
  logEvent('bike_sold', bikeInfo);
  const ref = doc(productsRef, product.id);
  return updateDoc(ref, {
    stock: 0,
    out_of_stock: true,
    sold_date: Date.now(),
    date_added: deleteField(),
  });
}

// we'll discuss this one
// prob use our ai guy
export async function getFreqPurchasedWith(
  categories: string[],
  l = DEFAULT_LIMIT
) {
  const q = query(
    productsRef,
    where(`category${categories.length - 1}`, '==', categories.pop()),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => cleanLocation(d.data()));
  return { results };
}

export function getProductId(): string {
  const newDocRef = doc(productsRef);
  const id = newDocRef.id;
  return id;
}

export async function addProductDoc(data: ProductDocument) {
  const parsedData = productSchema.parse(data);
  const docRef = doc(productsRef, parsedData.id);

  Object.keys(parsedData).forEach(
    (key) =>
      // @ts-ignore typescript doesnt' like this
      parsedData[key] === undefined && delete parsedData[key]
  );
  return setDoc(docRef, parsedData);
}

export async function updateProductListingDoc(data: ProductDocument) {
  const parsedData = productSchema.parse(data);
  const docRef = doc(productsRef, parsedData.id);
  // Not using { merge: true} because the edit listing page makes sure the product passed is fully correct
  return setDoc(docRef, parsedData);
}

export async function addProductDraftDoc(data: Partial<ProductDocument>) {
  // filter out any undefined values, todo do this better
  removeUndefinedRecursive(data);
  const docRef = doc(productsRef, data.id);
  return setDoc(docRef, data);
}

export function removeUndefinedRecursive(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeUndefinedRecursive(obj[key]);
    } else if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
}

export async function updateProductDraftDoc(data: Partial<ProductDocument>) {
  const docRef = doc(productsRef, data.id);
  removeUndefinedRecursive(data);
  return updateDoc(docRef, data);
}

export async function reactivateProductDoc(product_id: string) {
  const docRef = doc(productsRef, product_id);
  return updateDoc(docRef, {
    date_updated: Date.now(),
    is_draft: false,
    out_of_stock: false,
  });
}

// Constraints
function getBikeConstraints(bikes: {
  years: string[];
  makes: string[];
  models: string[];
}) {
  const allBikeCombos = [];
  const allBikeMakes = [];
  const allBikeMakesModels = [];
  if (!bikes.makes?.length) return null;

  for (const make of bikes.makes) {
    allBikeMakes.push({ make });
    for (const model of bikes.models) {
      allBikeMakesModels.push({ make, model });
      for (const year of bikes.years) {
        allBikeCombos.push({ year, make, model });
      }
    }
  }
  if (
    !allBikeCombos.length &&
    !allBikeMakes.length &&
    !allBikeMakesModels.length
  )
    return null;
  return where('fitting_bikes.bikes', 'array-contains-any', [
    ...(allBikeMakes.length ? allBikeMakes.map((b) => ({ make: b.make })) : []),
    ...(allBikeMakesModels.length
      ? allBikeMakesModels.map((b) => ({ make: b.make, model: b.model }))
      : []),
    ...(allBikeCombos.length
      ? allBikeCombos.map((b) => ({
          make: b.make,
          model: b.model,
          year: +b.year,
        }))
      : []),
  ]);
}
function getCategoryConstraint(
  categoryQueryValue: string | null,
  showDirtBikes?: boolean
) {
  return !!categoryQueryValue
    ? where('category', '==', categoryQueryValue)
    : where('category', 'in', [
        'Riding Gear',
        'Mountain Bikes',
        'Bike Parts',
        'Collectibles',
        'Accessories',
        ...(showDirtBikes ? ['Dirt Bikes'] : []),
      ]);
}

function getSubCategoryConstraint(
  cat1: string | null,
  cat2: string | null,
  cat3: string | null
) {
  return !!cat3
    ? where('category3', '==', cat3)
    : !!cat2
    ? where('category2', '==', cat2)
    : !!cat1
    ? where('category1', '==', cat1)
    : null;
}

function getSortConstraint(sortQueryValue?: string | null) {
  return sortQueryValue === 'priceAsc'
    ? orderBy('price', 'asc')
    : sortQueryValue === 'priceDesc'
    ? orderBy('price', 'desc')
    : orderBy('date_added', 'desc');
}

function getBrandsConstraint(brandsQueryValue?: string[] | null) {
  if (brandsQueryValue?.length) {
    if (brandsQueryValue.length === 1) {
      return where('brand', '==', brandsQueryValue[0]);
    } else if (brandsQueryValue.length > 1) {
      return where('brand', 'in', brandsQueryValue);
    }
  }
  return null;
}

function getBootSizesConstraint(bootSizesQueryValue?: string[] | null) {
  let numArray: number[] = [];
  if (bootSizesQueryValue?.length)
    numArray = bootSizesQueryValue.map((c) => +c);
  return !bootSizesQueryValue?.length
    ? null
    : bootSizesQueryValue.length === 1
    ? where('size.number', '==', +numArray[0])
    : where('size.number', 'in', numArray);
}

function getShirtSizesConstraint(shirtSizeConstraint?: string[] | null) {
  if (shirtSizeConstraint?.length)
    shirtSizeConstraint = shirtSizeConstraint.map((c) => c);
  return !shirtSizeConstraint?.length
    ? null
    : shirtSizeConstraint.length === 1
    ? where('size.letter', '==', shirtSizeConstraint[0])
    : where('size.letter', 'in', shirtSizeConstraint);
}

function getPantSizesConstraint(pantSizeConstraint?: string[] | null) {
  if (pantSizeConstraint?.length)
    pantSizeConstraint = pantSizeConstraint.map((c) => c);
  return !pantSizeConstraint?.length
    ? null
    : pantSizeConstraint.length === 1
    ? where('size.number', '==', +pantSizeConstraint[0])
    : where(
        'size.number',
        'in',
        pantSizeConstraint.map((c) => +c)
      );
}

/**
 * Return the size constraint filter for Firestore objects with the size.letter
 * attribute (e.g. gloves and helmets).
 */
function getLetterSizeConstraint(letterSizeConstraint?: string[] | null) {
  if (letterSizeConstraint?.length)
    letterSizeConstraint = letterSizeConstraint.map((c) => c);
  return !letterSizeConstraint?.length
    ? null
    : letterSizeConstraint.length === 1
    ? where('size.letter', '==', letterSizeConstraint[0])
    : where('size.letter', 'in', letterSizeConstraint);
}

function getGloveSizesConstraint(gloveSizeConstraint?: string[] | null) {
  return getLetterSizeConstraint(gloveSizeConstraint);
}

function getHelmetSizesConstraint(helmetSizeConstraint?: string[] | null) {
  return getLetterSizeConstraint(helmetSizeConstraint);
}

// Helpers
function argsRequireTypesense(
  args: Pick<
    BaseGetProductsArgs,
    | 'keyword'
    | 'page'
    | 'minPrice'
    | 'maxPrice'
    | 'brands'
    | 'conditions'
    | 'sort'
    | 'shipTo'
    | 'sellerType'
    | 'bikeMakes'
    | 'bikeModels'
    | 'bikeYears'
    | 'letterSizes'
    | 'numberSizes'
  >
) {
  return (
    args.sellerType ||
    args.keyword ||
    args.page > 1 ||
    ((args.minPrice || args.maxPrice) && args.sort === 'newest') ||
    (args.brands &&
      args.brands.length > 1 &&
      args.conditions &&
      args.conditions.length > 1) ||
    args.brands?.length > 30 ||
    (args.shipTo && args.shipTo.length >= 1) ||
    (args.bikeMakes && args.bikeMakes.length >= 1) ||
    (args.bikeModels && args.bikeModels.length >= 1) ||
    (args.bikeYears && args.bikeYears.length >= 1) ||
    (args.letterSizes && args.letterSizes.length >= 1) ||
    (args.numberSizes && args.numberSizes.length >= 1)
  );
}

export async function reportItem(
  id: string,
  reason: ReasonTypes,
  isUser: boolean
): Promise<void> {
  const col = isUser ? 'reports_users' : 'reports_products';
  const colRef = collection(db, col);
  const ref = doc(colRef, id);
  return setDoc(ref, { [reason]: increment(1) }, { merge: true });
}

export async function getOnSaleByCategory(
  category1: string,
  l = DEFAULT_LIMIT
) {
  const q = query(
    productsRef,
    where('category', '==', category1),
    where('on_sale', '==', true),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  return docs.map((d) => cleanLocation(d.data()));
}

export async function getCuratedListByCategory(
  list: string,
  category: string,
  l = DEFAULT_LIMIT
) {
  const q = query(
    productsRef,
    where('category', '==', category),
    where('curated_lists', 'array-contains', list),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  return docs.map((d) => cleanLocation(d.data()));
}

export async function updateFavoriteCount(
  id: string,
  isLiked = false
): Promise<void> {
  const ref = doc(productsRef, id);
  const val = isLiked ? -1 : 1;
  return updateDoc(ref, { favorite_count: increment(val) });
}

export async function updateProductByKey(
  id: string,
  key: keyof ProductDocument,
  value: any
): Promise<void> {
  const ref = doc(productsRef, id);
  return updateDoc(ref, { [key]: value });
}

export async function updateProductByKeys(
  id: string,
  keyValues: Record<string, any>
): Promise<void> {
  const ref = doc(productsRef, id);
  return updateDoc(ref, keyValues);
}

export async function bumpProduct(id: string): Promise<void> {
  const ref = doc(productsRef, id);
  return updateDoc(ref, { date_added: Date.now(), bumps: increment(-1) });
}

export async function removeProductFromFavorites(
  uid: string,
  productId: string
): Promise<void> {
  const ref = doc(usersRef, uid);
  return updateDoc(ref, { favorites: arrayRemove(productId) });
}

export async function addProductToFavorites(
  uid: string,
  productId: string
): Promise<void> {
  const ref = doc(usersRef, uid);
  return updateDoc(ref, { favorites: arrayUnion(productId) });
}

export async function addProductToCuratedList(
  productId: string,
  curated_list: string
): Promise<void> {
  const ref = doc(productsRef, productId);
  return updateDoc(ref, { curated_lists: arrayUnion(curated_list) });
}

export async function removeProductFromCuratedList(
  productId: string,
  curated_list: string
): Promise<void> {
  const ref = doc(productsRef, productId);
  return updateDoc(ref, { curated_lists: arrayRemove(curated_list) });
}

export async function getMasterProductInfo(cat2?: CategoryDocument): Promise<{
  min: number;
  max: number;
  cat1_name: string;
  brand_name: string;
  brand_slug: string;
}> {
  if (!cat2)
    return {
      min: 0,
      max: 0,
      cat1_name: '',
      brand_name: '',
      brand_slug: '',
    };
  const minPriceQ = query(
    productsRef,
    where('category2', '==', cat2.name),
    orderBy('price', 'asc'),
    limit(1)
  );

  const maxPriceQ = query(
    productsRef,
    where('category2', '==', cat2.name),
    orderBy('price', 'desc'),
    limit(1)
  );

  const [minPrice, maxPrice, cat1Snap, brand] = await Promise.all([
    getDocs(minPriceQ),
    getDocs(maxPriceQ),
    getCategoryByID(cat2.id),
    getBrandByName(cat2.name.split(' ')[0]),
  ]);
  return {
    min: minPrice.docs[0]?.get('price') ?? 0,
    max: maxPrice.docs[0]?.get('price') ?? 0,
    cat1_name: cat1Snap?.name ?? '',
    brand_name: brand?.name ?? '',
    brand_slug: brand?.url ?? '',
  };
}

export function setVacationMode(uid: string, on = false) {
  return httpsCallable<{ uid: string; on: boolean }, void>(
    functions,
    FirebaseCallable.vacationMode
  )({ uid, on });
}

const getBrandsFromResult = (products: ProductDocument[]) =>
  [...new Set(products.map((p) => p.brand))].sort().filter(Boolean);

const getAvailabilityConstraint = (availabilityQueryValue?: Availability) => {
  if (availabilityQueryValue === 'inStock') {
    return [where('out_of_stock', '==', false)];
  }
  if (availabilityQueryValue === 'sold') {
    return [where('out_of_stock', '==', true), where('is_draft', '==', false)];
  }
  if (availabilityQueryValue === 'draft') {
    return [where('is_draft', '==', true)];
  }
  if (availabilityQueryValue === 'onSale') {
    return [where('on_sale', '==', true), where('out_of_stock', '==', false)];
  }
  if (
    availabilityQueryValue === 'notSold' ||
    availabilityQueryValue === 'paymentFailed'
  ) {
    // p.is_auction && p.out_of_stock && p.stock
    return [
      where('is_auction', '==', true),
      where('out_of_stock', '==', true),
      where('stock', '>', 0),
    ];
  }
  return [];
};

export const cleanLocation = (p: ProductDocument) => {
  if (!p) return p;
  if (p.shipment) delete p.shipment;
  return {
    ...p,
    ...(p?.location && {
      location: {
        latitude: p.location.latitude ?? 0,
        longitude: p.location.longitude ?? 0,
      },
    }),
  };
};

export const getProductDescription = (product?: ProductDocument | null) => {
  if (!product) return '';
  if (!product.description && product.description_html) {
    return product.description_html
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&');
  }
  return product.description;
};

export function getVariationPrice(
  product: ProductDocument,
  selectedVariations: Variation[] | null
) {
  if (!selectedVariations) return product.price;
  const letterSize = selectedVariations?.find((v) => isNaN(+v.size));
  const numberSize = selectedVariations?.find(
    (v) => !isNaN(+v.size) && v.is_number
  );
  const isColor = selectedVariations?.find((v) => !!v.color);
  if (letterSize && numberSize && !isColor) {
    return letterSize.price + numberSize.price;
  }
  if (letterSize) {
    if (isColor) {
      return Math.max(letterSize.price, isColor.price);
    }
    return letterSize.price;
  }
  if (numberSize) {
    if (isColor) {
      return Math.max(numberSize.price, isColor.price);
    }
    return numberSize.price;
  }
  return product.price;
}

export function getSizeFromVariation(selectedVariations: Variation[] | null) {
  const letterSize = selectedVariations?.find(
    (v) => v.size && !v.is_number && !v.color && v.size !== 'N/A'
  );
  const numberSize = selectedVariations?.find((v) => v.is_number && !v.color);
  return {
    ...(letterSize && {
      letter: letterSize.size as string,
    }),
    ...(numberSize && {
      number: +numberSize.size,
    }),
  };
}

export async function getBikeGraph(year: number, make: string, model: string) {
  const q = query<ProductDocument, DocumentData>(
    productsRef,
    where('year', '==', year),
    where('make', '==', make),
    where('model', '==', model),
    orderBy('sold_date')
  );
  const snapshot = await getDocs(q);
  if (snapshot.empty) return null;
  const min: number = snapshot.docs[0].get('price');
  const max: number = snapshot.docs.pop()!.get('price');
  const count = snapshot.size;

  let sum = 0;
  let prices: number[] = [];
  let soldDates: number[] = [];
  const bikes = snapshot.docs.map((d) => d.data());
  bikes.sort((a, b) => (a.sold_date ?? 0) - (b.sold_date ?? 0));
  bikes.forEach((bike) => {
    sum += bike.price;
    prices.push(Math.ceil(bike.price / 500) * 500);
    soldDates.push(bike.sold_date ?? 0);
  });
  return { prices, soldDates, min, max, count, avg: sum / count };
}

export async function getProductViews(id: string) {
  const productViewsRef = collection(db, 'product_views');
  const data = doc(productViewsRef, id);
  const docSnap = await getDoc(data);
  return (docSnap.data() as { views: number }) ?? { views: 0 };
}

export function generateTypesenseFilters(args: BaseGetProductsArgs) {
  let filters = '';
  if (args.keyword || (!args.category1 && !args.showDirtBikes)) {
    // NOT category = dirt bikes on search page or non category pages
    filters += `category:!="Dirt Bikes" && `;
  }
  if (args.current_cat) {
    if (args.current_cat.product_cats?.ride_type) {
      filters += `ride_types:=["${args.current_cat.product_cats.ride_type}"] && `;
    }
    if (args.current_cat.product_cats?.category) {
      filters += `category:=[${args.current_cat.product_cats.category}] && `;
    }
    if (args.current_cat.product_cats?.category1) {
      filters += `category1:=[${args.current_cat.product_cats.category1}] && `;
    }
    if (args.current_cat.product_cats?.category2) {
      filters += `(category2:=[${args.current_cat.product_cats.category2}] || category3:=[${args.current_cat.product_cats.category2}]) && `;
    }
    if (args.current_cat.product_cats?.category3) {
      filters += `(category3:=[${args.current_cat.product_cats.category3}] || category2:=[${args.current_cat.product_cats.category3}]) && `;
    }
    if (args.current_cat.product_cats?.category4) {
      filters += `category4:=[${args.current_cat.product_cats.category4}] && `;
    }
  }

  if (args.bikeMakes?.length) {
    filters += `(fitting_bikes.bikes.make:[\`${args.bikeMakes.join(
      '`,`'
    )}\`]) && `;
  }
  if (args.bikeModels?.length) {
    filters += `(fitting_bikes.bikes.model:[\`${args.bikeModels.join(
      '`,`'
    )}\`]) && `;
  }
  if (args.bikeYears?.length) {
    filters += `(fitting_bikes.bikes.year:[${args.bikeYears.join(', ')}]) && `;
  }
  if (args.seller_id) filters += `seller_id:"${args.seller_id}" && `;
  if (args.is_featured) filters += 'is_featured:true && ';
  if (args.on_sale) filters += 'on_sale:true && ';
  if (args.curated_list)
    filters += `curated_lists:=[\`${args.curated_list.join('`,`')}\`] && `;
  if (args.shipTo && args.shipTo.length) {
    filters += `(shipping_costs.code:[${args.shipTo.join(
      ','
    )}] || is_flat_rate:false) && `;
  }
  if (args.shipFrom && args.shipFrom.length) {
    filters += `(country_code:[${args.shipFrom.join(',')}])`;
  }
  if (args.minPrice && args.maxPrice)
    filters += `price:[${args.minPrice}..${args.maxPrice}] && `;
  else if (args.minPrice) filters += `price:>=${args.minPrice} && `;
  else if (args.maxPrice) filters += `price:<=${args.maxPrice} && `;
  if (args.brands?.length) {
    filters += `brand:[${args.brands.map((b) => `${b}`).join(',')}] && `;
  }
  if (args.conditions?.length) {
    const condition = args.conditions.includes('New') ? 'New' : 'Used';
    // if its new , filter by new, else filter by not new
    if (condition === 'New') {
      filters += `condition:"${condition}" && `;
    } else {
      filters += `condition:!="New" && `;
    }
  }
  if (args.numberSizes?.length) {
    filters += `(size.number:[${args.numberSizes.join(
      ','
    )}] || variations.size:[${args.numberSizes.join(',')}] ) && `;
  }
  if (args.letterSizes?.length) {
    filters += `(size.letter:[${args.letterSizes.join(
      ','
    )}] || variations.size:[${args.letterSizes.join(',')}] ) && `;
  }
  if (args.genders?.length) {
    filters += `gender:${args.genders[0]} && `;
  }
  if (args.sellerType) {
    if (args.sellerType === 'dealer') {
      filters += `seller_type:"dealer" && `;
    } else {
      filters += `seller_type:!="dealer" && `;
    }
  }
  if (args.color?.length) {
    filters += `variations.color:=[${args.color
      .map((c) => `"${c}"`)
      .join(',')}] && `;
  }
  if (args.rideTypes?.length && !filters.includes('ride_types:=')) {
    filters += `ride_types:=[${args.rideTypes.map((t) => `"${t}"`)}] && `;
  }
  if (filters.endsWith('&& ')) filters = filters.slice(0, -4);
  return filters;
}

export async function getCauses() {
  const causesRef = collection(db, 'causes');
  const q = query(causesRef);
  const { docs } = await getDocs(q);
  return docs.map((d) => d.data() as CauseDocument);
}

export async function getCauseById(cause: string) {
  const causesRef = collection(db, 'causes');
  const ref = doc(causesRef, cause);
  const docSnap = await getDoc(ref);
  return docSnap.data() as CauseDocument;
}

export async function getReportedProducts() {
  const reportsRef = collection(db, 'reports_products');
  const q = query(reportsRef, limit(10));
  const { docs } = await getDocs(q);
  const res: Map<string, any> = new Map();
  docs.forEach((d) => {
    res.set(d.id, d.data());
  });
  const products = await getProductsByIds([...res.keys()]);
  const productsWithReports = products.map((p) => ({
    ...p,
    reports: res.get(p.id),
  }));
  return productsWithReports;
}

export async function getPartsForYourBike(bike: Bike, uid: string) {
  if (!bike.year || !bike.make || !bike.model || !uid) return { results: [] };

  const user_profile_index = 'user_profiles_alias';

  const user_profile_res = await client
    .collections(user_profile_index)
    .documents()
    .search({
      q: '*',
      query_by: 'uid',
      filter_by: `uid:=${uid}`,
    });
  const user_profile = user_profile_res.hits?.[0].document as UserProfile;
  if (!user_profile) {
    const constraints = [
      where('category', '==', 'Bike Parts'),
      where('out_of_stock', '==', false),
      getBikeConstraints({
        years: [bike.year.toString()],
        makes: [bike.make],
        models: [bike.model],
      }),
      orderBy('date_added', 'desc'),
    ].filter(nonNullable);
    const q = query(productsRef, ...constraints, limit(12));
    const { docs } = await getDocs(q);
    return {
      results: docs.map((d) => cleanLocation(d.data())),
    };
  }
  const filter_by = `category:"Bike Parts" && (fitting_bikes.bikes.make:=\`${bike.make}\` && fitting_bikes.bikes.model:=\`${bike.model}\` && fitting_bikes.bikes.year:=${bike.year})`;
  const res = await client.multiSearch.perform<ProductDocument[]>({
    searches: [
      {
        filter_by,
        vector_query: `embedding:([${user_profile.activity_profile}], k:12)`,
        query_by: 'title',
        q: '*',
        per_page: 3,
        collection: 'products_alias',
      },
      {
        filter_by: filter_by + ' && condition:New',
        query_by: 'title',
        q: '*',
        per_page: 3,
        collection: 'products_alias',
        sort_by: 'favorite_count:desc',
      },
      {
        filter_by: filter_by + ' && condition:Used',
        query_by: 'title',
        q: '*',
        per_page: 3,
        collection: 'products_alias',
        sort_by: 'favorite_count:desc',
      },
      {
        filter_by: filter_by + ' && condition:Used',
        query_by: 'title',
        q: '*',
        per_page: 3,
        collection: 'products_alias',
        sort_by: 'date_added:desc',
      },
      {
        filter_by: filter_by + ' && condition:New',
        query_by: 'title',
        q: '*',
        per_page: 3,
        collection: 'products_alias',
        sort_by: 'date_added:desc',
      },
    ],
  });
  // flatten the results, even odd
  let docs = res.results
    .map((r) => r.hits?.map((h) => h.document) ?? [])
    .flat();

  // remove duplicates
  docs = docs.filter((d, i) => docs.findIndex((dd) => dd.id === d.id) === i);

  // interweave results
  docs = [
    ...docs.filter((_, i) => i % 3 === 0),
    ...docs.filter((_, i) => i % 3 === 1),
    ...docs.filter((_, i) => i % 3 === 2),
  ];

  return {
    results: docs,
  };
}

export async function getPartsForYourBikeFromRecommend(bike: Bike) {
  try {
    const res = await httpsCallable<{ bike: Bike }, ProductDocument[]>(
      functions,
      'v2_getShapedPartsForBike'
    )({ bike });
    return { results: res.data };
  } catch (e) {
    logError(e);
    return { results: [] };
  }
}

export async function getDirtBikeShipping({
  productId,
  zipCode,
  pickupZip,
  bikePrice,
}: {
  productId?: string;
  zipCode: string;
  pickupZip?: string;
  bikePrice?: number;
}) {
  const res = await httpsCallable<
    {
      productId?: string;
      zipCode: string;
      pickupZip?: string;
      bikePrice?: number;
    },
    {
      total: number; // amount to charge
      quote: number; // shipping before markup
      insurance_cost: number; // w markup
      shipping_cost: number; // w markup
    }
  >(
    functions,
    FirebaseCallable.calculateBikeShipping
  )({ productId, zipCode, pickupZip, bikePrice });
  return res.data;
}

export async function getInStockCount() {
  // const snap = await db
  //       .collection("products")
  //       .where("out_of_stock", "==", false)
  //       .orderBy("date_added", "desc")
  //       .get();

  const q = query(
    productsRef,
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc')
  );
  const count = await getCountFromServer(q);
  return count.data().count;
}

export async function setStoreOnSale({
  uid,
  salePercent,
}: {
  uid: string;
  salePercent: number;
}) {
  await httpsCallable<{ uid: string; salePercent: number }, void>(
    functions,
    FirebaseCallable.setStoreOnSale
  )({ uid, salePercent });
}

export async function getProductsFromRecommend({
  user,
  page = 1,
  limit = 10,
  filters,
}: {
  user: UserDocument;
  page?: number;
  limit?: number;
  filters?: PageSideFilters;
}): Promise<{
  products: ProductDocument[];
  errors: string[];
  facet_counts: FacetCounts[];
}> {
  const vector_query = await getActivityVector(user.uid);
  if (!vector_query) throw new Error('No vector query found');
  const res = await getPickedForYou({
    user,
    page,
    vector_query,
    limit,
    filters,
  });
  if (res.errors?.length) throw new Error(res.errors?.[0]);
  if (res && res.products.length < limit) {
    const res2 = await getProducts({
      keyword: '',
      limit: (limit - res.products.length) as any,
      sort: 'newest',
      page: 1,
      maxPrice: filters?.maxPrice ?? null,
      minPrice: filters?.minPrice ?? null,
      conditions: filters?.conditions ?? [],
      brand: filters?.brands[0] ?? null,
      brands: filters?.brands ?? [],
      allBrands: [],
      genders: filters?.genders ?? [],
      color: filters?.color ?? [],
      category: filters?.category?.[0] ?? '',
      category1: filters?.category?.[1] ?? '',
      category2: filters?.category?.[2] ?? '',
      category3: filters?.category?.[3] ?? '',
      categories: [],
      mainCats: [],
      current_cat: null,
      is_featured: false,
      on_sale: filters?.onsale ?? false,
      is_auction: false,
      seller_id: null,
      curated_list: null,
      numberSizes: filters?.numberSizes ?? [],
      letterSizes: filters?.letterSizes ?? [],
      bikeYears: filters?.bikeYears ?? [],
      bikeMakes: filters?.bikeMakes ?? [],
      bikeModels: filters?.bikeModels ?? [],
      shipTo: filters?.shipTo ?? [],
      shipFrom: filters?.shipFrom ?? [],
      sellerType: filters?.sellerType?.[0],
      rideTypes: filters?.rideTypes ?? [],
    });
    res.products.push(
      ...res2.results.filter((r) => !res.products.some((p) => p.id === r.id))
    );
  }
  return res;
}

const getPickedForYou = async ({
  user,
  page,
  vector_query,
  filters,
  bike,
  limit,
}: {
  user?: UserDocument;
  page?: number;
  vector_query?: string;
  filters?: PageSideFilters;
  bike?: Bike;
  limit?: number;
}) => {
  const collection = 'products_alias';
  const searches: MultiSearchRequestSchema[] = [];

  if (Object.values(filters ?? {}).every((f) => !f)) {
    filters = undefined;
  }

  let defaultFilter = ``;
  if (filters?.rideTypes?.length) {
    defaultFilter += `ride_types:=[${filters.rideTypes.map(
      (t) => `"${t}"`
    )}] && `;
  }
  if (!filters?.sellerType?.[0] || filters.sellerType[0] !== 'dealer') {
    defaultFilter += 'seller_type:!=dealer';
  } else {
    defaultFilter += `seller_type:=dealer`;
  }

  if (filters?.conditions?.length) {
    if (filters.conditions.includes('New')) {
      defaultFilter += ` && condition:="New"`;
    } else {
      defaultFilter += ` && condition:!="New"`;
    }
  }
  if (filters?.brands?.length) {
    defaultFilter += ` && brand:[${filters.brands
      .map((b) => `${b}`)
      .join(',')}]`;
  }
  if (filters?.minPrice && filters?.maxPrice) {
    defaultFilter += ` && price:[${filters.minPrice}..${filters.maxPrice}]`;
  } else if (filters?.minPrice) {
    defaultFilter += ` && price:>=${filters.minPrice}`;
  } else if (filters?.maxPrice) {
    defaultFilter += ` && price:<=${filters.maxPrice}`;
  }

  if (user?.uid) defaultFilter += ` && seller_id:!=${user.uid}`;

  if (filters?.category?.[0]) {
    defaultFilter += ` && category:=\`${filters.category[0]}\``;
  }

  const gender = user?.gender ?? 'M';
  const sizes = user?.sizes ?? {};
  if (
    !filters?.category?.some((c) => c) ||
    filters.category?.[0] === 'Riding Gear'
  ) {
    if (!filters?.category?.[1] || filters.category[1] === 'Gear') {
      let filter_by = `${defaultFilter} && category1:=Gear && gender:=${gender} && `;
      if (sizes.jersey && sizes.pants) {
        filter_by += `(category2:=\`Gear Combos\` && ((size.letter:=${sizes.jersey} || variations.size:=${sizes.jersey}) && (size.number:=${sizes.pants} || variations.size:=${sizes.pants}))`;
      }
      if (sizes.jersey) {
        if (filter_by.endsWith('))')) filter_by += ' || ';
        filter_by += `(category2:=Jerseys && (size.letter:=${sizes.jersey} || variations.size:=${sizes.jersey}))`;
      }
      if (sizes.pants) {
        if (filter_by.endsWith('))')) filter_by += ' || ';
        filter_by += `(category2:Pants && (size.number:=${sizes.pants} || variations.size:=${sizes.pants}))`;
      }
      // commented cuz sizes.letter1 not in ts schema
      if (sizes.gloves) {
        if (filter_by.endsWith('))')) filter_by += ' || ';
        filter_by += `(category2:Gloves && (size.letter1:=${sizes.gloves} || variations.size:=${sizes.gloves}))`;
      }
      // no sizes
      if (filter_by.endsWith(' && ')) filter_by = filter_by.slice(0, -4);
      else filter_by += ')';

      searches.push({
        collection,
        q: '*',
        filter_by,
        query_by: '',
        page,
        per_page: limit,
        exclude_fields: 'embedding,fitting_bikes',
        ...(vector_query && { vector_query }),
      });
    }

    if (!filters?.category?.[1] || filters.category[1] === 'Boots') {
      let filter_by = `${defaultFilter} && category1:=Boots && gender:=${gender}`;
      if (sizes.boots)
        filter_by += ` && (size.number:=${sizes.boots} || variations.size:=${sizes.boots})`;
      if (filters?.category?.[3]) {
        filter_by += ` && category3:=${filters.category[3]}`;
      }
      searches.push({
        collection,
        q: '*',
        filter_by,
        query_by: '',
        page,
        per_page: limit,
        exclude_fields: 'embedding,fitting_bikes',
        ...(vector_query && { vector_query }),
      });
    }

    if (!filters?.category?.[1] || filters.category[1] === 'Helmets') {
      let filter_by = `${defaultFilter} && category1:=Helmets && gender:=${gender}`;
      if (sizes.helmet)
        filter_by += ` && (size.letter:=${sizes.helmet} || variations.size:=${sizes.helmet})`;
      if (filters?.category?.[3]) {
        filter_by += ` && category3:=${filters.category[3]}`;
      }
      searches.push({
        collection,
        q: '*',
        filter_by,
        query_by: '',
        page,
        per_page: limit,
        exclude_fields: 'embedding,fitting_bikes',
        ...(vector_query && { vector_query }),
      });
    }

    if (
      filters?.category?.[1] &&
      !['Gear', 'Boots', 'Helmets'].includes(filters.category[1])
    ) {
      let filter_by = `${defaultFilter} && category1:=\`${filters.category[1]}\``;
      if (filters.category[2])
        filter_by += ` && category2:=\`${filters.category[2]}\``;
      searches.push({
        collection,
        q: '*',
        filter_by,
        query_by: '',
        page,
        per_page: limit,
        exclude_fields: 'embedding,fitting_bikes',
        ...(vector_query && { vector_query }),
      });
    }
  }

  const bikes = bike ? [bike] : user?.bikes ?? [];
  if (
    !filters?.category?.some((c) => c) ||
    filters.category?.[0] === 'Bike Parts'
  ) {
    let filter_by = defaultFilter;
    if (!filter_by.includes('category:='))
      filter_by += ' && category:=`Bike Parts`';
    if (filters?.category?.[1])
      filter_by += ` && category1:=\`${filters.category[1]}\``;
    if (filters?.category?.[2])
      filter_by += ` && category2:=\`${filters.category[2]}\``;
    filter_by += ' && (';
    bikes?.forEach((b, i, arr) => {
      if (b.make) {
        filter_by += ` ((fitting_bikes.bikes.make:=\`${b.make}\`)`;
        if (b.year) filter_by += ` && (fitting_bikes.bikes.year:=${b.year})`;
        if (b.model)
          filter_by += ` && (fitting_bikes.bikes.model:=\`${b.model}\`)`;
        if (i === arr.length - 1) filter_by += `))`;
        else filter_by += `) ||`;
      }
    });
    if (filter_by.endsWith(' && (')) filter_by = filter_by.slice(0, -4);
    searches.push({
      collection,
      q: '*',
      filter_by,
      query_by: '',
      page,
      per_page: limit,
      exclude_fields: 'embedding,fitting_bikes',
      ...(vector_query && { vector_query }),
    });
  }

  if (
    !filters?.category?.some((c) => c) ||
    filters.category?.[0] === 'Accessories'
  ) {
    let filter_by = defaultFilter;
    if (!filter_by.includes('category:='))
      filter_by += ' && category:=`Accessories`';
    if (filters?.category?.[1])
      filter_by += ` && category1:=\`${filters.category[1]}\``;
    if (filters?.category?.[2])
      filter_by += ` && category2:=\`${filters.category[2]}\``;
    searches.push({
      collection,
      q: '*',
      filter_by,
      query_by: '',
      page,
      per_page: limit,
      exclude_fields: 'embedding,fitting_bikes',
      ...(vector_query && { vector_query }),
    });
  }

  if (
    !filters?.category?.some((c) => c) ||
    filters.category?.[0] === 'Collectibles'
  ) {
    let filter_by = defaultFilter;
    if (!filter_by.includes('category:='))
      filter_by += ' && category:=`Collectibles`';
    if (filters?.category?.[1])
      filter_by += ` && category1:=\`${filters.category[1]}\``;
    if (filters?.category?.[2])
      filter_by += ` && category2:=\`${filters.category[2]}\``;
    searches.push({
      collection,
      q: '*',
      filter_by,
      query_by: '',
      page,
      per_page: limit,
      exclude_fields: 'embedding,fitting_bikes',
      ...(vector_query && { vector_query }),
    });
  }

  if (!searches.length)
    return {
      products: [] as ProductDocument[],
      errors: ['No searches'],
      facet_counts: [] as FacetCounts[],
    };
  try {
    const { results } = await client.multiSearch.perform<ProductDocument[]>(
      { searches },
      {
        facet_by:
          'brand,condition,category,category1,category2,category3,seller_type,gender,size,variations.color',
        max_facet_values: 25,
      }
    );
    const products: ProductDocument[] = [];
    const errors: string[] = [];
    results.forEach((r) => {
      if (r.hits?.length)
        r.hits.forEach((h) =>
          products.push({
            ...h.document,
            from_recommend: true,
          })
        );
      const err: string = (r as any)?.error;
      if (err) errors.push(err);
    });
    products.sort(() => (Math.random() > 0.5 ? 1 : -1));
    const mergedFacetCounts = getMultiSearchFacetCounts(results);
    return {
      products,
      errors,
      // facet_counts: results[0].facet_counts ?? [],
      facet_counts: mergedFacetCounts,
    };
  } catch (e) {
    logError(e, `getPickedForYou - web - ${user?.uid}`);
    return {
      products: [] as ProductDocument[],
      errors: [(e as Error).message],
      facet_counts: [] as FacetCounts[],
    };
  }
};

function getMultiSearchFacetCounts(results: SearchResponse<ProductDocument>[]) {
  const mergedFacetCounts: FacetCounts[] = [];
  results.forEach((r) => {
    if (r.facet_counts) {
      const currFacetCounts = r.facet_counts;
      currFacetCounts.forEach((f: FacetCounts) => {
        const currCounts = f.counts;
        currCounts.forEach((c) => {
          const mergedFacetCount = mergedFacetCounts.find(
            (mf) => mf.field_name === f.field_name
          );
          if (mergedFacetCount) {
            const mergedCount = mergedFacetCount.counts.find(
              (mc) => mc.value === c.value
            );
            if (mergedCount) {
              mergedCount.count += c.count;
            } else {
              mergedFacetCount.counts.push(c);
            }
          } else {
            mergedFacetCounts.push({
              field_name: f.field_name,
              counts: [c],
              stats: f.stats,
            });
          }
        });
      });
    }
  });
  return mergedFacetCounts;
}

export async function sendOffersToLikers(product_id: string, price: number) {
  try {
    const sendOffer = httpsCallable<
      { product_id: string; price: number },
      { errorCount: number; successCount: number }
    >(functions, 'v2_sendOffersToLikers');

    const res = await sendOffer({ product_id, price });
    return { results: res.data };
  } catch (e) {
    logError(e);
    throw e;
  }
}

export async function getBreadcrumbs(product: ProductDocument) {
  try {
    let filter_by = ``;
    if (product.ride_types.length)
      filter_by += `product_cats.ride_type:=[${product.ride_types
        .map((rt) => `\"${rt}\"`)
        .join(',')}] && `;
    if (product.category) {
      filter_by += `product_cats.category:=\"${product.category}\" && `;
    }
    if (product.category1) {
      filter_by += `product_cats.category1:=\"${product.category1}\" && `;
    }
    if (product.category2) {
      filter_by += `product_cats.category2:=\"${product.category2}\" && `;
    }
    if (product.category3) {
      filter_by += `(product_cats.category3:=\"${product.category3}\" || product_cats.category2:=\"${product.category3}\") && `;
    }
    if (filter_by.endsWith(' && ')) filter_by = filter_by.slice(0, -4);
    let res = await client.collections('categories_alias').documents().search({
      q: '*',
      filter_by,
      query_by: 'name',
      per_page: 1,
      drop_tokens_threshold: 0,
      typo_tokens_threshold: 0,
      include_fields: 'breadcrumbs',
    });
    const hit = res.hits?.[0]?.document as CategoryDocument;
    if (!hit) {
      return [];
    }
    const bcs = hit.breadcrumbs ?? [];
    return bcs.slice(product.ride_types && bcs.length > 2 ? 1 : 0).map((b) => ({
      label: b.name,
      href: b.slug,
    }));
  } catch (e) {
    logError(e);
    return [];
  }
}
